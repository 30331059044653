import styled from 'styled-components';


const UsersWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 57px);
    h3 {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 0px;
        text-align: left;
        color: #4e667e;
    }
`;

const ContentWrapper = styled.div`
    padding: 25px;
    background: #f3f8fb;
    min-height: calc(100vh - 60px);
    padding-bottom: 100px;
    .update-profile-btn {
        margin: 50px 0px 0px 50px;
    }
`;

const BlankContentWrapper = styled.div`
    background: #fff;
    height: 100%;
    box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
`;

const AddTextWrapper = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    margin-top: 5px;
    cursor: pointer;
    color: #0f9af0;
    img {
        margin-right: 7px;
    }

`;

export { UsersWrapper, ContentWrapper, BlankContentWrapper, AddTextWrapper }