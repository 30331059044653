import { Col, Row, Skeleton } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Button from "component/Button";
import Card from "component/Card";

// redux
import { consultSelector, fetchAllConsults } from "redux/reducers/consults";
import { fetchAllForms, formSelector } from "redux/reducers/forms";
import { fetchAllReviews, reviewSelector } from "redux/reducers/reviews";
import { fetchBalance, transactionSelector } from "redux/reducers/transactions";
import { userSelector, fetchAllUsersCount } from "redux/reducers/users";
import {
  fetchAllLabRequests,
  labRequestSelector
} from "redux/reducers/labRequests";

// svg
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { ReactComponent as UserIcon } from "assets/icons/avatar.svg";
import { ReactComponent as ReviewsIcon } from "assets/icons/feedback.svg";
import { ReactComponent as FormsIcon } from "assets/icons/bullet-form.svg";
import { ReactComponent as EncountersIcon } from "assets/icons/doctor.svg";
import { ReactComponent as ServiceIcon } from "assets/icons/healthcare.svg";

import EncounterTable from "../Encounters/containers/EncounterTable";
import LabRequestsTable from "../LabRequests/containers/LabRequestsTable";
import {
  DashboardWrapper,
  ContentWrapper,
  StatsCardWrapper,
  OverviewIntroCardWrapper
} from "./styles";

const Dashboard = ({ dashboardMode }: any) => {
  const dispatch = useDispatch();
  const { balance: balanceList, isBalanceLoading } =
    useSelector(transactionSelector);
  const BalanceNGN = balanceList
    ? balanceList.filter((item: any) => item.currency === "ngn")[0] || {}
    : {};
  const { balance } = BalanceNGN;

  const { usersCount, isUsersCountLoading } = useSelector(userSelector);
  const { consults, isConsultsLoading } = useSelector(consultSelector);
  const { labRequests, isLabRequestsLoading } = useSelector(labRequestSelector);
  const { reviews, isReviewsLoading } = useSelector(reviewSelector);
  const { avg = 0 }: { avg: number } = reviews || {};
  const { forms, isFormsLoading } = useSelector(formSelector);

  useEffect(() => {
    dispatch(fetchBalance());
    dispatch(fetchAllUsersCount());
    dispatch(fetchAllConsults());
    dispatch(fetchAllLabRequests());
    dispatch(fetchAllReviews());
    dispatch(fetchAllForms());
  }, [dispatch]);

  const statsData1 = [
    {
      title: `Users`,
      value: usersCount || 0,
      loading: isUsersCountLoading,
      actionText: "View users",
      icon: <UserIcon />,
      url: "/users",
      dashboardMode: ["integration", "connect"]
    },
    {
      title: "Encounters",
      value: consults?.length || 0,
      loading: isConsultsLoading,
      actionText: "View encounters",
      icon: <EncountersIcon />,
      url: "/encounters",
      dashboardMode: ["integration"]
    }
  ];
  const statsData2 = [
    {
      title: "Service requests",
      value: labRequests?.length || 0,
      loading: isLabRequestsLoading,
      actionText: "View service requests",
      icon: <ServiceIcon />,
      url: "/service-requests",
      dashboardMode: ["integration", "connect"]
    },
    {
      title: "Consult forms",
      value: forms?.length || 0,
      loading: isFormsLoading,
      actionText: "View consult forms",
      icon: <FormsIcon />,
      url: "/forms",
      dashboardMode: ["integration"]
    }
  ];

  const profile = JSON.parse(localStorage.getItem("pneumaCurrentUser") || "");
  const { first_name, last_name } = profile || {};

  return (
    <DashboardWrapper>
      <ContentWrapper>
        <Row gutter={0}>
          <Col span={24} className='overview-intro-pane'>
            <OverviewIntroCardWrapper>
              <div className='circles' />
              <div className='inner'>
                <Row align='middle'>
                  <Col xs={24} md={12}>
                    <h3>
                      Welcome to your dashboard, {first_name || last_name}!
                    </h3>
                    <p>
                      Here, you can see all your users, encounters and wallet
                      balance
                    </p>
                  </Col>
                  <Col xs={24} md={12} style={{ textAlign: "right" }}>
                    <Link to='/manage-account'>
                      <Button type='secondary'>Manage Account</Button>
                    </Link>
                  </Col>
                </Row>
              </div>
            </OverviewIntroCardWrapper>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16} equal-heights={true}>
              <Col xs={24} md={10} style={{ height: "100%" }}>
                <StatsCardItem
                  title='Wallet Balance'
                  icon={<WalletIcon />}
                  loading={isBalanceLoading}
                  url={"/wallet"}
                  value={`₦ ${(balance / 100 || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2
                  })}`}
                  actionText={"View transaction history"}
                />
              </Col>
              {statsData1
                .filter((item) => {
                  return item.dashboardMode.includes(dashboardMode);
                })
                .map(
                  ({ title, value, loading, actionText, url, icon }, index) => {
                    return (
                      <Col
                        xs={12}
                        md={7}
                        key={index}
                        style={{ height: "100%" }}
                      >
                        <StatsCardItem
                          title={title}
                          value={value}
                          icon={icon}
                          loading={loading}
                          url={url}
                          actionText={actionText}
                        />
                      </Col>
                    );
                  }
                )}
              {dashboardMode === "integration" && (
                <Col
                  xs={24}
                  md={10}
                  style={{ paddingBottom: "0px", height: "100%" }}
                >
                  <StatsCardItem
                    title='Reviews & Ratings'
                    loading={isReviewsLoading}
                    icon={<ReviewsIcon />}
                    url={"/reviews"}
                    value={`${(avg || 0).toFixed(2)}`}
                    actionText={"View reviews"}
                  />
                </Col>
              )}
              {statsData2
                .filter((item) => {
                  return item.dashboardMode.includes(dashboardMode);
                })
                .map(
                  ({ title, value, loading, actionText, url, icon }, index) => {
                    return (
                      <Col
                        xs={24}
                        md={7}
                        key={index}
                        style={{ height: "100%" }}
                      >
                        <StatsCardItem
                          title={title}
                          value={value}
                          loading={loading}
                          icon={icon}
                          url={url}
                          actionText={actionText}
                        />
                      </Col>
                    );
                  }
                )}
            </Row>
          </Col>
        </Row>
        <Row className='encounters-pane'>
          {dashboardMode === "integration" ? (
            <>
              <Col xs={0} md={12}>
                <h2>Recent Encounters</h2>
              </Col>
              <Col xs={0} md={12} style={{ textAlign: "right" }}>
                <Link to='/encounters'>See All</Link>
              </Col>
            </>
          ) : (
            <>
              <Col xs={0} md={12}>
                <h2>Recent Requests</h2>
              </Col>
              <Col xs={0} md={12} style={{ textAlign: "right" }}>
                <Link to='/service-requests'>See All</Link>
              </Col>
            </>
          )}
          <Col xs={0} md={24}>
            {dashboardMode === "integration" ? (
              <EncounterTable count={5} hasPagination={false} marginTop={10} />
            ) : (
              <LabRequestsTable
                count={5}
                hasPagination={false}
                marginTop={10}
              />
            )}
          </Col>
        </Row>
      </ContentWrapper>
    </DashboardWrapper>
  );
};

export default Dashboard;

const StatsCardItem = ({
  title,
  value,
  loading,
  actionText,
  url,
  icon
}: any) => {
  return (
    <StatsCardWrapper active={false} isCurrency={title === "Wallet Balance"}>
      <Card>
        {loading ? (
          <Skeleton paragraph={{ rows: 1 }} />
        ) : (
          <div className='content'>
            <div className='flex'>
              <div>
                <p className='title'>{title}</p>
                <h2>
                  {value.toLocaleString()}
                  {title === "Reviews & Ratings" && <small>/5.0</small>}
                </h2>
              </div>
              <span className='icon'>{icon}</span>
            </div>
            <span className='link'>
              <Link to={url}>
                {actionText} <i className='lni lni-arrow-right'></i>
              </Link>
            </span>
          </div>
        )}
      </Card>
    </StatsCardWrapper>
  );
};
