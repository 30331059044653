import { Row } from "antd";

import { EncounterWrapper, ContentWrapper } from "./styles";
import EncounterTable from "./containers/EncounterTable";

const Encounters = () => {
    return (
        <EncounterWrapper>
            <ContentWrapper>
                <EncounterTable />
            </ContentWrapper>
        </EncounterWrapper>
    )
}

export default Encounters;