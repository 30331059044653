import styled from 'styled-components';


const ProfileWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const ContentWrapper = styled.div`
    padding: 15px;
    background: #f3f8fb;
    min-height: calc(100vh - 60px);
    padding-bottom: 100px;
    .update-profile-btn {
        margin: 10px 0px 0px 0px;
    }
    .btn-add {
        padding: 0px;
        font-size: 13px;
    }
    .reviews-header {
        border-bottom: 1px solid #f2f2f2;
    }
    .reviews-lhs {
        padding-top: 26px;
        border-right: 1px solid #f2f2f2;
        @media(max-width: 767px) {
            border: none;
        }
        h1 {
            font: normal normal normal 32px/18px DM Sans;
            letter-spacing: 0px;
            color: #4E667E;
            opacity: 1;
            margin-bottom: 8px;
        }
        .stars {
            img {
                width: 11.35px;
                margin-right: 2.13px;
            }
        }
        p.big-link {
            font: normal normal normal 14px/14px DM Sans;
            letter-spacing: 0px;
            color: #0f9af0;
        }
        .progress {
            margin-bottom: 10px;
            .progress-item {
                display: flex;
                margin-bottom: 5px;
                align-items: center;
                span {
                    font-size: 12px;
                    font: normal normal normal 12px/12px DM Sans;
                    letter-spacing: 0px;
                    color: #002E46;
                    opacity: 0.75;
                }
                .ant-progress {
                    .ant-progress-bg {
                        background-color: #0f9af0;
                    }
                }
            }
        }
        p {
            margin-top: 10px;
            font: normal normal normal 12px/11px DM Sans;
            letter-spacing: 0px;
            color: #002E46;
        }
    }
    .reviews-rhs {
        padding-top: 10px;
    }
`;

const ReviewCardsWrapper = styled.div`
    background: #fff;
    box-shadow: 0px 2px 5px #002E4626;
    border: 0.800000011920929px solid #002E4626;
    border-radius: 4px;
    display: flex;
    padding: 14px 11px;
    img.avatar {
        margin-right: 10px;
        max-width: 100%;
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
    .stars {
        img {
            margin-right: 1.25px;
        }
    }
    .details {
        flex-grow: 1;
        .title {
            font: normal normal normal 14px/14px DM Sans;
            letter-spacing: 0px;
            color: #002E46;
            margin-bottom: 8px;
        }
        .sub-text {
            font: normal normal normal 12px/9px DM Sans;
            letter-spacing: 0px;
            color: #4E667E;
            margin-bottom: 8px;
        }
    }
`;

const ReviewMainCardsWrapper = styled.div`
    background: #fff;
    border: 1px solid #002E4640;
    border-radius: 6px;
    padding: 17px 17px;
    margin-bottom: 18px;
    img.avatar {
        margin-right: 10px;
        max-width: 100%;
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
    .title {
        p {
            margin-bottom: 4px;
            font: normal normal medium 12px/14px DM Sans;
            letter-spacing: 0px;
            color: #4E667E;
            margin-bottom: 0px;
        }
        span {
            font: normal normal medium 10px/12px DM Sans;
            letter-spacing: 0px;
            color: #4E667E;
            opacity: 0.5;
            font-size: 12px;
        }
    }
    .content {
        font: normal normal normal 13px/18px DM Sans;
        letter-spacing: 0px;
        color: #4E667E;
        margin-bottom: 10px;
        margin-top: 13px;
    }
    .stars {
        img {
            width: 11.55px;
            margin-right: 4px;
            /* object-fit: none; */
        }
    }
`

export { ProfileWrapper, ContentWrapper, ReviewCardsWrapper, ReviewMainCardsWrapper }