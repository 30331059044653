import styled from 'styled-components';


const TopupModalWrapper = styled.div`
    padding: 20px 50px 51px !important;

    p.title {
        font-size: 13px;
    }
    .ant-input-number {
        margin-top: 5px;
        border-radius: 8px;
        input {
            height: 40px;
            font-size: 13px;
        }
    }
    .ant-form-item-explain.ant-form-item-explain-error {
        font-size: 13px;
    }
    .ant-form-item-label label {
        color: #333 !important;
        /* font-weight: 400; */
        text-transform: uppercase;
        font-size: 10px;
    }
    .paystack-button {
        background: #0f9af0;
        color: #fff;
        height: 40px;
        border-radius: 4px;
        padding: 7px 15px;
        font-size: 13px;
        font-family: "DM Sans";
        font-weight: 400;
        border: none;
        line-height: 9px;
        text-align: center;
    }
`;


export { TopupModalWrapper };