import { Skeleton } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux
import {
  fetchAllMyProviders,
  getNetworkBookingsCount,
  providersSelector
} from "redux/reducers/providers";

import { ProvidersWrapper } from "./styles";
import ProvidersTable from "./containers/ProvidersTable";

function Providers() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllMyProviders());
    dispatch(getNetworkBookingsCount());
  }, [dispatch]);

  const {
    myProviders,
    isMyProvidersLoading,
    totalBookingsCount,
    getBookingsCountLoading
  } = useSelector(providersSelector);

  return (
    <ProvidersWrapper>
      <div className='header'>
        <div className='single-card'>
          <h3>Providers in Network</h3>
          {isMyProvidersLoading ? (
            <Skeleton paragraph={{ rows: 1 }} />
          ) : (
            <h5>
              {
                myProviders.filter(
                  (provider) => provider.status === "in_network"
                )?.length
              }
            </h5>
          )}
        </div>
        <div className='single-card'>
          <h3>Service Bookings</h3>
          {getBookingsCountLoading ? (
            <Skeleton paragraph={{ rows: 1 }} />
          ) : (
            <h5>{totalBookingsCount.total}</h5>
          )}
        </div>
      </div>

      <ProvidersTable />
    </ProvidersWrapper>
  );
}

export default Providers;
