import { message } from "antd";
import Swal from "sweetalert2";
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

const accessPublicKey = window.localStorage.getItem("accessPublicKey");
const accessSecretKey = window.localStorage.getItem("accessSecretKey");
const accessToken = window.localStorage.getItem("accessToken");

// TODO - figure out whats wrong with the dev URL & cors
const appInstance = process.env.REACT_APP_ENVIRONMENT as string;

const baseURL = process.env.REACT_APP_BASE_URL_PRODUCTION;

const httpPublic: AxiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${accessPublicKey}` }
});

httpPublic.defaults.headers.post["Content-Type"] = "application/json";

httpPublic.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

export const httpSecret: AxiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${accessSecretKey}` }
});

httpSecret.defaults.headers.post["Content-Type"] = "application/json";

httpSecret.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

export const httpWithJWT: AxiosInstance = axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${accessToken}` }
});

httpWithJWT.defaults.headers.post["Content-Type"] = "application/json";

httpWithJWT.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    const errorMsg = response?.data?.message || response?.data?.error;
    if (response) {
      if (
        (response?.status === 400 && errorMsg === "Token expired") ||
        response?.status === 401
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

const logOutProvider = () => {
  window.localStorage.removeItem("accessToken");
  window.localStorage.removeItem("_profile");
  window.location.replace("/");
};

export default httpPublic;
