import { Modal, Form, Row, Col } from "antd";
import Input from "../../../../component/Input";
import { PasswordAPIRequestModalWrapper } from "./styles";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../component/Button";
import { authSelector, getAPIKeys } from "../../../../redux/reducers/auth";

const PasswordAPIRequestModal = (props: any) => {
  const dispatch = useDispatch();
  const {
    visible,
    handleCancel,
  } = props;

  const { isAPIKeysLoading } = useSelector(authSelector);

  const handleSubmit = async(values: any) => {
    await dispatch(getAPIKeys(values));
    handleCancel()
  }

  return (
    <Modal
      title="Enter Password"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <PasswordAPIRequestModalWrapper>
        <p>For security reasons, enter your password to view your live secret keys.</p>
        <Form
          name="licenseForm"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Input
                type="password"
                placeholder="Password"
                formItem={{
                  name: "password",
                  rules: [
                    {
                      required: true,
                      message:
                        "Please input your password",
                    },
                  ],
                }}
                required
              />
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "20px",
              textAlign: "right",
              paddingTop: "20px",
              borderTop: "1px solid #f2f2f2",
            }}
          >
            <Col span={24}>
              <Button htmlType="submit" key="submit" type="primary">
                {isAPIKeysLoading ? "Loading..." : "Continue"}
              </Button>
            </Col>
          </Row>
        </Form>
      </PasswordAPIRequestModalWrapper>
    </Modal>
  );
};

export default PasswordAPIRequestModal;
