import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import { httpSecret } from "utils/api";
import { message } from "antd";
import { requestAccountActivationFailed } from "./auth";
import httpPublic from "utils/api";

export interface UserError {
  message: string;
}

export interface UserState {
  user: any;
  users: {
    data: Array<any>;
    pagination: { size: number; next: string | null; previous: string | null };
  };
  usersCount: number;
  isUsersCountLoading: boolean;
  isUserLoading: boolean;
  isUsersLoading: boolean;
  isCreateUserLoading: boolean;
  isVerifyUserLoading: boolean;
  isRequestUserAccessLoading: boolean;
  hasCreatedUser: boolean;
  hasVerifiedUser: boolean | null;
  hasRequestedUserAccess: boolean;
  userError: UserError;
  usersError: UserError;
  usersCountError: UserError;
  createUserError: UserError;
  verifyUserError: UserError;
  requestUserAccessError: UserError;
}

export const initialState: UserState = {
  user: null,
  users: { data: [], pagination: { size: 10, next: "", previous: "" } },
  usersCount: 0,
  isUserLoading: true,
  isUsersCountLoading: false,
  isUsersLoading: true,
  isCreateUserLoading: false,
  isVerifyUserLoading: false,
  isRequestUserAccessLoading: false,
  hasCreatedUser: false,
  hasVerifiedUser: null,
  hasRequestedUserAccess: false,
  userError: { message: "" },
  usersError: { message: "" },
  usersCountError: { message: "" },
  createUserError: { message: "" },
  verifyUserError: { message: "" },
  requestUserAccessError: { message: "" }
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    // CREATE USER
    createUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateUserLoading = payload;
      state.hasCreatedUser = false;
    },
    createUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.user = payload;
      state.hasCreatedUser = true;
    },
    createUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.createUserError = payload;
      state.user = null;
      state.hasCreatedUser = false;
    },
    // VERIFY USER
    resetHasVerifiedUser: (state) => {
      state.hasVerifiedUser = null;
    },
    verifyUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isVerifyUserLoading = payload;
    },
    verifyUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.hasVerifiedUser = true;
    },
    verifyUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.verifyUserError = payload;
      state.hasVerifiedUser = false;
    },
    // REQUEST USER ACCESS
    requestUserAccessLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isRequestUserAccessLoading = payload;
      state.hasRequestedUserAccess = false;
    },
    requestUserAccessSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.hasRequestedUserAccess = true;
    },
    requestUserAccessFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.requestUserAccessError = payload;
      state.hasRequestedUserAccess = false;
    },
    // FETCH SINGLE USER
    fetchUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserLoading = payload;
    },
    fetchUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.user = payload;
    },
    fetchUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.userError = payload;
      state.user = null;
    },
    // FETCH ALL USERS
    fetchUsersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUsersLoading = payload;
    },
    fetchUsersSuccess: (state, { payload }: PayloadAction<any>) => {
      state.users = {
        data: payload.data,
        pagination: {
          size: payload.pagination.size,
          next: payload.pagination.next,
          previous: payload.pagination.previous
        }
      };
    },
    fetchUsersFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.usersError = payload;
    },
    // FETCH ALL USERS COUNT
    fetchUsersCountLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUsersCountLoading = payload;
    },
    fetchUsersCountSuccess: (state, { payload }: PayloadAction<number>) => {
      state.usersCount = payload;
    },
    fetchUsersCountFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.usersCountError = payload;
    }
  }
});

export const {
  createUserLoading,
  createUserSuccess,
  createUserFailed,
  resetHasVerifiedUser,
  verifyUserLoading,
  verifyUserSuccess,
  verifyUserFailed,
  requestUserAccessLoading,
  requestUserAccessSuccess,
  requestUserAccessFailed,
  fetchUserLoading,
  fetchUserSuccess,
  fetchUserFailed,
  fetchUsersLoading,
  fetchUsersSuccess,
  fetchUsersFailed,
  fetchUsersCountLoading,
  fetchUsersCountSuccess,
  fetchUsersCountFailed
} = usersSlice.actions;
export const userSelector = (state: { users: UserState }) => state.users;
export default usersSlice.reducer;

/** Actions */

export const createUser =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(createUserLoading(true));
    await httpPublic
      .post(`/users`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(createUserSuccess(user));
        dispatch(fetchAllUsers());
        message.success("User created successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(createUserFailed(message));
      });
    dispatch(createUserLoading(false));
  };

export const verifyUser =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(verifyUserLoading(true));
    await httpPublic
      .post(`/users/verify`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(verifyUserSuccess(user));
        // message.success("User verified successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(verifyUserFailed(message));
      });
    dispatch(verifyUserLoading(false));
  };

export const requestUserAccess =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(requestUserAccessLoading(true));
    await httpPublic
      .post(`/users/request_access`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(requestUserAccessSuccess(user));
        message.success("User access requested successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(requestAccountActivationFailed(message));
      });
    dispatch(requestUserAccessLoading(false));
  };

export const approveUserAccessRequest =
  (otp: string): AppThunk =>
  async (dispatch) => {
    dispatch(createUserLoading(true));
    await httpPublic
      .post(`/users/approve_request/${otp}`)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(createUserSuccess(user));
        message.success("User successfully onboarded");
      })
      .catch((err) => {
        const messages = {
          message: err?.response?.data?.message || "An error occurred"
        };
        message.error("Wrong OTP! Please go back and request OTP again");
        dispatch(createUserFailed(messages));
      });
    dispatch(createUserLoading(false));
  };

export const fetchSingleUser =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchUserLoading(true));
    await httpSecret
      .get(`/users/${id}`)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(fetchUserSuccess(user));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(fetchUserFailed(message));
      });
    dispatch(fetchUserLoading(false));
  };

export const fetchAllUsers =
  (size = 10, next?: string, previous?: string): AppThunk =>
  async (dispatch) => {
    dispatch(fetchUsersLoading(true));
    await httpSecret
      .get(
        `/users/paginate?size=${size}
      ${previous ? `&prev=${previous}` : ""}
      ${next ? `&next=${next}` : ""}`
      )
      .then((res) => {
        const users = res?.data?.data;
        dispatch(fetchUsersSuccess(users));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred"
        };
        dispatch(fetchUsersFailed(_message));
        message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(fetchUsersLoading(false));
  };

// TODO - handle parts of application where entire user list is required i.e search

export const fetchAllUsersCount = (): AppThunk => async (dispatch) => {
  dispatch(fetchUsersCountLoading(true));
  try {
    const res = await httpSecret.get("/users/count");
    dispatch(fetchUsersCountSuccess(res.data.data.total));
  } catch (err: any) {
    const _message = {
      message: err?.response?.data?.message || "An error occurred"
    };
    dispatch(fetchUsersCountFailed(_message));
    message.error(err?.response?.data?.message || "An error occurred");
  } finally {
    dispatch(fetchUsersCountLoading(false));
  }
};
