import { combineReducers } from "@reduxjs/toolkit";

import AuthReducer from "./auth";
import ExaminationReducer from "./examinations";
import EncountersReducer from "./encounters";
import SymptomReducer from "./symptoms";
import DiagnosisReducer from "./diagnoses";
import ConsultReducer from "./consults";
import MessageReducer from "./messages";
import InvestigationReducer from "./investigations";
import MedicationReducer from "./medications";
import TreatmentsReducer from "./treatments";
import UsersReducer from "./users";
import TransactionsReducer from "./transactions";
import ReviewsReducer from "./reviews";
import FormsReducer from "./forms";
import LabRequestsReducer from "./labRequests";
import ProvidersReducer from "./providers";

const rootReducer = combineReducers({
  auth: AuthReducer,
  encounters: EncountersReducer,
  examinations: ExaminationReducer,
  symptoms: SymptomReducer,
  medications: MedicationReducer,
  treatments: TreatmentsReducer,
  investigations: InvestigationReducer,
  diagnoses: DiagnosisReducer,
  consults: ConsultReducer,
  messages: MessageReducer,
  users: UsersReducer,
  transactions: TransactionsReducer,
  reviews: ReviewsReducer,
  forms: FormsReducer,
  labRequests: LabRequestsReducer,
  providers: ProvidersReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
