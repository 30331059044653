import { UsersWrapper, ContentWrapper } from "./styles";
import UsersTable from "./containers/UsersTable";

const Users = ({ dashboardMode }: any) => {
  return (
    <UsersWrapper>
      <ContentWrapper>
        <UsersTable dashboardMode={dashboardMode} />
      </ContentWrapper>
    </UsersWrapper>
  );
};

export default Users;
