import styled from 'styled-components';


const AddUserModalWrapper = styled.div`
    padding: 0px !important;
    .otp-pane {
        text-align: center;
        label {
            font-size: 13px;
        }
        input:not([value=""]) {
            border: 2px solid #0f9af0 !important;
        }
    }
`;

export { AddUserModalWrapper }