import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 30px auto;
`;

export const Filters = styled.div`
  position: relative;

  .ant-tabs-nav {
    max-width: fit-content;
    margin-bottom: 100px;

    @media (min-width: 1024px) {
      margin-bottom: 32px;
    }

    .ant-tabs-tab-btn {
      color: #344054;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      user-select: none;
      opacity: 0.8;
    }

    .ant-tabs-tab {
      background: #fafafa3d;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);
      background: #fff;

      .ant-tabs-tab-btn {
        opacity: 1;
      }
    }
  }

  .sub-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    max-width: 700px;
    position: absolute;
    top: 42px;
    left: 0;
    width: 100%;

    @media (min-width: 1024px) {
      top: 0;
      right: 0;
      width: 50%;
      left: auto;
    }

    @media (min-width: 1340px) {
      top: 0;
      right: 0;
      width: 100%;

      .ant-input-search {
        flex: 2;
      }
    }

    > button {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
`;

export const TableWrapper = styled.div`
  .ant-card {
    .ant-card-body {
      border-radius: 8px;
    }
  }
`;

export const TableBody = styled.div`
  border-radius: 8px;

  .ant-card {
    box-shadow: none !important;

    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }

  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }

  .ant-table-wrapper {
    border-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table {
    border-radius: 8px;

    .odd-row td.ant-table-cell {
      background: #0f9af01a;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }

    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 16px;
      border-bottom: 0.5px solid #1890ff;
    }

    tr {
      td:first-child,
      th:first-child {
        padding: 10px 20px;
      }
    }

    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      color: #025bbb;
    }

    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;
    }
  }

  .empty-wrapper {
    display: grid;
    place-content: center;
    padding-top: 32px;
    padding-bottom: 32px;

    .empty-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: center;

      h4 {
        margin: 24px 0 8px;
        color: #222;
        text-align: center;
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
      }

      p {
        color: var(--gray-4, #667085);
        text-align: center;
        font-family: "DM Sans", sans-serif;
        max-width: 240px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }

  .action-btns {
    display: flex;
    align-items: center;
    gap: 8px;

    div:first-child {
      button {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    div:nth-of-type(2) {
      button {
        border-radius: 4px;
        border: 1px solid #0faff0;
        background-color: transparent;
        color: #0faff0;
        font-family: "DM Sans", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 120%;
      }
    }
  }

  .custom-pagination {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  display: grid;
  place-content: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
  }

  h5 {
    color: #344054;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    max-width: 400px;
    text-align: center;
    margin-top: 12px;
  }
`;
