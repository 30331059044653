import styled from "styled-components";

export const ProvidersWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 57px);
  padding: 25px 25px 100px;
  background-color: rgb(243, 248, 251);

  .header {
    display: flex;
    gap: 20px;
    align-items: center;

    .single-card {
      flex-basis: 50%;
      border-radius: 4px;
      border: 1px solid #e5e8ec;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 24px;

      h3 {
        color: #475569;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        margin: 0 0 6px;

        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }

      h5 {
        color: #0d0d0d;
        font-family: "DM Sans", sans-serif;
        font-size: 32px;
        font-weight: 500;
        margin: 0;

        @media (min-width: 1024px) {
          font-size: 48px;
        }
      }
    }
  }
`;
