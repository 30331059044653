import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http, { httpSecret, httpWithJWT } from '../../utils/api'
import { message } from 'antd'
import httpPublic from '../../utils/api'

export interface FormError {
    message: string
}

export interface FormState {
    form: any
    forms: Array<any>
    isFormLoading: boolean
    isFormsLoading: boolean
    formError: FormError
    formsError: FormError
}

export const initialState: FormState = {
    form: null,
    forms: [],
    isFormLoading: true,
    isFormsLoading: true,
    formError: { message: '' },
    formsError: { message: '' }
}

export const formsSlice = createSlice({
    name: 'forms',
    initialState,
    reducers: {
        // FETCH SINGLE REVIEW
        fetchFormLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isFormLoading = payload
        },
        fetchFormSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.form = payload;
        },
        fetchFormFailed: (state, { payload }: PayloadAction<FormError>) => {
            state.formError = payload;
            state.form = null;
        },
        // FETCH ALL REVIEWS
        fetchFormsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isFormsLoading = payload
        },
        fetchFormsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.forms = payload;
        },
        fetchFormsFailed: (state, { payload }: PayloadAction<FormError>) => {
            state.formsError = payload;
        },
    },
})

export const { 
    fetchFormLoading, fetchFormSuccess, fetchFormFailed, 
    fetchFormsLoading, fetchFormsSuccess, fetchFormsFailed, 
} = formsSlice.actions;
export const formSelector = (state: { forms: FormState }) => state.forms;
export default formsSlice.reducer;


/** Actions */

export const fetchSingleForm = (id: any): AppThunk => async (dispatch) => {
    dispatch(fetchFormLoading(true))
    await httpSecret.get(`/telehealth/forms/${id}`)
        .then((res) => {
            const form= res?.data?.data;
            dispatch(fetchFormSuccess(form))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchFormFailed(message));
        })
    dispatch(fetchFormLoading(false));
}

export const fetchAllForms = (): AppThunk => async (dispatch) => {
    dispatch(fetchFormsLoading(true))
    await httpPublic.get(`/telehealth/forms`)
        .then((res) => {
            const forms = res?.data?.data;
            dispatch(fetchFormsSuccess(forms));
            // message.success("Forms Fetched Successfully");
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchFormsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(fetchFormsLoading(false));
}