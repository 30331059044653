import { BrowserRouter, Route, Switch } from "react-router-dom";

// Layouts
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import OnboardingLayoutRoute from "./layouts/OnboardingLayout";

// Onboarding Screens
import Login from "./screens/Onboarding/Login";
import Register from "./screens/Onboarding/Register";
import RecoverPassword from "./screens/Onboarding/RecoverPassword";
import ResetPassword from "./screens/Onboarding/ResetPassword";
import RequestOTP from "./screens/Onboarding/RequestOTP";
import EnterOTP from "./screens/Onboarding/EnterOTP";

// Dashboard Screens
import Profile from "./screens/Profile";
import Encounters from "./screens/Encounters";
import Transactions from "./screens/Transactions";
import Users from "./screens/Users";
import Providers from "./screens/Providers";
import SingleProvider from "screens/SingleProvider";
import Reviews from "./screens/Reviews";

import { ROUTES } from "./constants/routes";
import "./App.css";
import "antd/dist/antd.css";
import TermsAndConditions from "./screens/Onboarding/TermsAndConditions";
import Forms from "./screens/Forms";
import LabRequests from "./screens/LabRequests";
import Dashboard from "./screens/Dashboard";

const Error404Page = () => (
  <>
    <h4>Error 404 Page</h4>
  </>
);

const App = () => {
  const dashboardMode =
    window.localStorage.getItem("pneumaDashboardMode") || "integration";
  return (
    <BrowserRouter>
      <Switch>
        <OnboardingLayoutRoute exact path={ROUTES.HOMEPAGE} component={Login} />
        <OnboardingLayoutRoute exact path={ROUTES.LOGIN} component={Login} />
        <OnboardingLayoutRoute
          exact
          path={ROUTES.REGISTER}
          component={Register}
        />
        <OnboardingLayoutRoute
          exact
          path={ROUTES.TERMS_AND_CONDITIONS}
          component={TermsAndConditions}
        />
        <OnboardingLayoutRoute
          exact
          path={ROUTES.RECOVER_PASSWORD}
          component={RecoverPassword}
        />
        <OnboardingLayoutRoute
          exact
          path={ROUTES.RESET_PASSWORD}
          component={ResetPassword}
        />
        <OnboardingLayoutRoute
          exact
          path={ROUTES.REQUEST_OTP}
          component={RequestOTP}
        />
        <OnboardingLayoutRoute
          exact
          path={ROUTES.ENTER_OTP}
          component={EnterOTP}
        />

        {/* Dashboard Screens */}
        <DashboardLayoutRoute
          exact
          path={ROUTES.DASHBOARD}
          component={Dashboard}
        />
        <DashboardLayoutRoute exact path={ROUTES.USERS} component={Users} />
        <DashboardLayoutRoute
          exact
          path={ROUTES.PROVIDERS}
          component={Providers}
        />
        <DashboardLayoutRoute
          exact
          path={ROUTES.SINGLE_PROVIDER}
          component={SingleProvider}
        />
        <DashboardLayoutRoute
          exact
          path={ROUTES.TRANSACTIONS}
          component={Transactions}
        />
        <DashboardLayoutRoute
          exact
          path={ROUTES.MANAGE_ACCOUNT}
          component={Profile}
        />
        <DashboardLayoutRoute
          exact
          path={ROUTES.LAB_INVESTIGATIONS}
          component={LabRequests}
        />
        {["integration"].includes(dashboardMode) && (
          <>
            <DashboardLayoutRoute
              exact
              path={ROUTES.REVIEWS_RATINGS}
              component={Reviews}
            />
            <DashboardLayoutRoute
              exact
              path={ROUTES.ENCOUNTERS}
              component={Encounters}
            />
            <DashboardLayoutRoute exact path={ROUTES.FORMS} component={Forms} />
          </>
        )}
        <Route component={Error404Page} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
