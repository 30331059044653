import styled from 'styled-components';


const ProfileWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const ContentWrapper = styled.div`
    padding: 15px 15px;
    min-height: calc(100vh - 60px);
    background: #fff;
    padding-bottom: 100px;
    h2 {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 0px;
        text-align: left;
        color: #4e667e;
        padding: 10px 20px;
    }
    .update-profile-btn {
        margin: 10px 0px 0px 0px;
    }
    .btn-add {
        padding: 0px;
        font-size: 13px;
    }
    .ant-tabs-nav {
        background: #fff;
        border: 1px solid #f2f2f2;
        padding: 0px 20px;
        margin: 0px 20px 30px;
        border-radius: 8px;
    }
    .ant-tabs-content {
        padding: 0px 20px;
    }
`;

const RegularInputWrapper = styled.input`
    height: 45px;
    width: 100%;
    border: 1px solid #f2f2f2;
    outline: none;
`

const BlankContentWrapper = styled.div`
    background: #fff;
    height: 100%;
    padding: 25px 15px;
    padding-right: 20px;
    box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
    h3 {
        opacity: 0.6;
    }
    p {
        font-size: 13px;
    }
    .ant-alert {
        margin-bottom: 5px;
        .ant-alert-message {
            font-size: 13px;
        }
    }
    .api-section {
        background: #f3f8fb;
        border: 1px solid #91d5ff;
        padding: 15px 15px;
        margin-bottom: 20px;
        p {
            margin-bottom: 10px;
        }
        label {
            font-weight: normal;
            margin-bottom: 5px;
        }
        .ant-input-affix-wrapper.ant-input-password {
            border: 1px solid #BFCAD0;
            padding: 5px 10px;
        }
    }
`;

const LicenseRowItemWrapper = styled.div`
    padding: 10px 0px;
    display: flex;
    img {
        width: 70px;
    }
    .certificate-details {
        flex-grow: 1;
        margin-left: 20px;
        .action-btns {
            float: right;
            span {
                margin-left: 10px;
                &.edit {
                    color: #0f9af0;
                }
                &.delete {
                    color: crimson;
                }
            }
        }
        h3 {
            margin-bottom: 0px;
        }
        p {
            margin-bottom: 0px;
            font-size: 13px;
        }
        span {
            color: #a3a3a3;
            font-size: 13px;
        }
    }
`;

const AddTextWrapper = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    margin-top: 5px;
    cursor: pointer;
    color: #0f9af0;
    img {
        margin-right: 7px;
    }
`;

export { ProfileWrapper, ContentWrapper, BlankContentWrapper, AddTextWrapper, LicenseRowItemWrapper, RegularInputWrapper }