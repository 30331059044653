import { Tabs, Input, Select, Button } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";

// redux
import {
  fetchAllProviders,
  providersSelector,
  fetchAllMyProviders
} from "redux/reducers/providers";

// svg,
import { ReactComponent as ExportIcon } from "assets/icons/export-icon.svg";

import AllProviders from "../AllProviders";
import NewProviders from "../NewProviders";
import MyProviders from "../MyProviders";

import { Wrapper, Filters, ErrorWrapper } from "./styles";

const { Search } = Input;

function ProvidersTable() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllProviders());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchAllMyProviders());
  }, [dispatch]);

  const {
    allProviders,
    allProvidersError,
    isAllProvidersLoading,
    myProviders,
    isMyProvidersLoading
  } = useSelector(providersSelector);

  const myActiveProviders = myProviders.filter(
    (provider) => provider.status === "in_network"
  );

  const myActiveProvidersCSV = myActiveProviders.map((provider) => ({
    name: provider.service_provider.name,
    category: provider.service_provider.category,
    state: provider.service_provider.address.state,
    city: provider.service_provider.address.city
  }));

  const recentProviders = allProviders.filter(
    (provider) =>
      moment().diff(moment(new Date(provider.created_at)), "week") <= 4
  );

  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [activeKey, setActiveKey] = useState(
    localStorage.getItem("providerActiveTab")! || "my-providers"
  );
  const onKeyChange = (key: string) => {
    localStorage.setItem("providerActiveTab", key);
    setActiveKey(key);

    // reset filters
    setSearchTerm("");
    setSelectedLocation("");
    setSelectedCategory("");
  };

  /** ALL PROVIDERS FILTERS ***/
  // filter all providers' list by business name / name
  let filteredProviders = allProviders?.filter(
    (provider) =>
      provider.name.toLowerCase().includes(searchTerm.trim()) ||
      provider.business_name.toLowerCase().includes(searchTerm.trim())
  );

  // filter all providers' list by selected location
  filteredProviders = filteredProviders?.filter((provider) =>
    provider.address.state
      .toLowerCase()
      .includes(selectedLocation.toLowerCase())
  );

  // filter all providers' list by selected category
  filteredProviders = filteredProviders?.filter((provider) =>
    provider.type.toLowerCase().includes(selectedCategory.toLowerCase())
  );
  /** END OF ALL PROVIDERS FILTERS ***/

  /** RECENT PROVIDERS FILTERS ***/
  // filter recent providers' list by business name / name
  let filteredRecentProviders = recentProviders?.filter(
    (provider) =>
      provider.name.toLowerCase().includes(searchTerm.trim()) ||
      provider.business_name.toLowerCase().includes(searchTerm.trim())
  );

  // filter recent providers' list by selected location
  filteredRecentProviders = filteredRecentProviders?.filter((provider) =>
    provider.address.state
      .toLowerCase()
      .includes(selectedLocation.toLowerCase())
  );

  // filter recent provider's list by selected category
  filteredRecentProviders = filteredRecentProviders?.filter((provider) =>
    provider.type.toLowerCase().includes(selectedCategory.toLowerCase())
  );
  /** END OF RECENT PROVIDERS FILTERS ***/

  /** MY PROVIDERS FILTERS ***/
  // filter my providers' list by business name / name
  let filteredMyProviders = myActiveProviders?.filter((provider) =>
    provider.service_provider?.name.toLowerCase().includes(searchTerm.trim())
  );

  // filter my providers' list by selected location
  filteredMyProviders = filteredMyProviders?.filter((provider) =>
    provider.service_provider.address.state
      .toLowerCase()
      .includes(selectedLocation.toLowerCase())
  );

  // filter my provider's list by selected category
  filteredMyProviders = filteredMyProviders?.filter((provider) =>
    provider.service_provider.category
      .toLowerCase()
      .includes(selectedCategory.toLowerCase())
  );

  /** END OF MY PROVIDERS FILTERS ***/

  /** ALL PROVIDERS OPTIONS ***/
  // generate state locations
  const locations = filteredProviders?.reduce((acc: string[], provider) => {
    if (!acc.includes(provider.address.state)) {
      acc.push(provider.address.state);
    }

    return acc;
  }, []);

  // provider location type options
  const locationVals = locations?.map((singleLocation) => ({
    label: capitalize(singleLocation),
    value: singleLocation
  }));

  // generate provider categories
  const specialties = filteredProviders?.reduce((acc: string[], provider) => {
    if (!acc.includes(provider.type)) {
      acc.push(provider.type);
    }
    return acc;
  }, []);

  // provider category type options
  const specialtiesVals = specialties?.map((specialty) => ({
    label: `${
      specialty.split("_").join(" ").charAt(0).toUpperCase() +
      specialty.split("_").join(" ").slice(1).toLowerCase()
    }`,
    value: specialty
  }));

  /** END OF ALL PROVIDERS OPTIONS ***/

  /** RECENT PROVIDERS OPTIONS ***/
  // generate provider locations for recent providers
  const recentLocations = filteredRecentProviders?.reduce(
    (acc: string[], provider) => {
      if (!acc.includes(provider.address.state)) {
        acc.push(provider.address.state);
      }

      return acc;
    },
    []
  );

  // recent provider locations options
  const recentLocationVals = recentLocations?.map((singleLocation) => ({
    label: capitalize(singleLocation),
    value: singleLocation
  }));

  // generate provider categories for recent providers
  const recentSpecialties = filteredRecentProviders?.reduce(
    (acc: string[], provider) => {
      if (!acc.includes(provider.type)) {
        acc.push(provider.type);
      }
      return acc;
    },
    []
  );

  // recent provider category type options
  const recentSpecialtiesVals = recentSpecialties?.map((specialty) => ({
    label: `${
      specialty.split("_").join(" ").charAt(0).toUpperCase() +
      specialty.split("_").join(" ").slice(1).toLowerCase()
    }`,
    value: specialty
  }));
  /** END OF RECENT PROVIDERS OPTIONS ***/

  /** MY PROVIDERS OPTIONS ***/
  // generate provider locations for my providers
  const myLocations = filteredMyProviders?.reduce((acc: string[], provider) => {
    if (!acc.includes(provider.service_provider.address.state)) {
      acc.push(provider.service_provider.address.state);
    }

    return acc;
  }, []);

  // my provider locations options
  const myLocationVals = myLocations?.map((singleLocation) => ({
    label: capitalize(singleLocation),
    value: singleLocation
  }));

  // generate provider categories for my providers
  const mySpecialties = filteredMyProviders?.reduce(
    (acc: string[], provider) => {
      if (!acc.includes(provider.service_provider.category)) {
        acc.push(provider.service_provider.category);
      }
      return acc;
    },
    []
  );

  // recent provider category type options
  const mySpecialtiesVals = mySpecialties?.map((specialty) => ({
    label: `${
      specialty.split("_").join(" ").charAt(0).toUpperCase() +
      specialty.split("_").join(" ").slice(1).toLowerCase()
    }`,
    value: specialty
  }));

  /** END OF MY PROVIDERS OPTIONS ***/

  if (allProvidersError.message) {
    return (
      <ErrorWrapper>
        <h5>{allProvidersError.message}. Please try again</h5>
      </ErrorWrapper>
    );
  }

  return (
    <Wrapper>
      <Filters>
        <Tabs
          activeKey={activeKey}
          onChange={onKeyChange}
          size='large'
          type='card'
          items={[
            {
              label: "My Providers",
              key: "my-providers",
              children: (
                <MyProviders
                  handleChangeTab={onKeyChange}
                  providers={filteredMyProviders}
                  isLoading={isMyProvidersLoading}
                />
              )
            },
            {
              label: "All Providers",
              key: "all-providers",
              children: (
                <AllProviders
                  providers={filteredProviders!}
                  myProviders={filteredMyProviders}
                  isLoading={isAllProvidersLoading || isMyProvidersLoading}
                />
              )
            },
            {
              label: "Recently added",
              key: "recent-providers",
              children: (
                <NewProviders
                  handleChangeTab={onKeyChange}
                  providers={filteredRecentProviders!}
                  myProviders={filteredMyProviders}
                  isLoading={isAllProvidersLoading || isMyProvidersLoading}
                />
              )
            }
          ]}
        />

        <div className='sub-filters'>
          <Search
            placeholder='Search by provider name'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <Select
            showSearch
            placeholder='Category'
            style={{ minWidth: "180px" }}
            options={[
              { label: "Filter by category", value: "" },
              ...(activeKey === "all-providers"
                ? specialtiesVals
                : activeKey === "recent-providers"
                ? recentSpecialtiesVals
                : mySpecialtiesVals)
            ]}
            value={selectedCategory}
            onChange={(val) => setSelectedCategory(val)}
          />

          <Select
            placeholder='State'
            showSearch
            style={{ minWidth: "140px" }}
            options={[
              { label: "Filter by location", value: "" },
              ...(activeKey === "all-providers"
                ? locationVals
                : activeKey === "recent-providers"
                ? recentLocationVals
                : myLocationVals)
            ]}
            value={selectedLocation}
            onChange={(val) => setSelectedLocation(val)}
          />

          {activeKey === "my-providers" ? (
            <Button>
              <CSVLink
                data={myActiveProvidersCSV}
                target='_blank'
                filename='My Providers'
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px"
                }}
              >
                <ExportIcon />
                Export
              </CSVLink>
            </Button>
          ) : null}
        </div>
      </Filters>
    </Wrapper>
  );
}

export default ProvidersTable;
