import { Col, Row, Skeleton } from "antd";
import Button from "../../component/Button";

import {
  TransactionsWrapper,
  ContentWrapper,
  StatsCardWrapper,
} from "./styles";
import TransactionsTable from "./containers/TransactionsTable";
import Card from "../../component/Card";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  fetchBalance,
  transactionSelector,
} from "../../redux/reducers/transactions";
import TopupModal from "./containers/TopupModal";

const Transactions = () => {
  const dispatch = useDispatch();
  const [isOpenTopupModal, setIsOpenTopupModal] = useState(false);

  useEffect(() => {
    dispatch(fetchBalance());
  }, [dispatch]);

  const { balance: balanceList, isBalanceLoading } = useSelector(transactionSelector);
  const BalanceNGN = balanceList
    ? balanceList.filter((item: any) => item.currency === "ngn")[0] || {}
    : {};
  const { balance } = BalanceNGN;
  return (
    <TransactionsWrapper>
      <ContentWrapper>
        <Row justify="space-between" align="middle">
          <Col xs={24} md={12}>
            <StatsCardWrapper>
              <Card padding={17}>
                {isBalanceLoading ? (
                  <div style={{marginTop: 10}}>
                    <Skeleton paragraph={{ rows: 1 }} />
                  </div>
                ) : (
                  <Row justify="space-between">
                    <div className="wallet-card-details">
                      <p>Wallet Balance</p>
                      {isBalanceLoading ? (
                        <Skeleton paragraph={{ rows: 0 }} />
                      ) : (
                        <h2>
                          ₦{" "}
                          {(balance ? (balance/100) : 0).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })}
                        </h2>
                      )}
                    </div>
                    <Button
                      type="primary"
                      className="btn-topUp"
                      onClick={() => setIsOpenTopupModal(true)}
                    >
                      Top up wallet
                    </Button>
                  </Row>
                )}
              </Card>
            </StatsCardWrapper>
          </Col>
        </Row>
        <TransactionsTable />
        <TopupModal
          isTopupModalVisible={isOpenTopupModal}
          setIsTopupModalVisible={setIsOpenTopupModal}
        />
      </ContentWrapper>
    </TransactionsWrapper>
  );
};

export default Transactions;
