import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, { httpSecret, httpWithJWT } from "../../utils/api";
import { message } from "antd";
import httpPublic from "../../utils/api";

export interface LabRequestError {
  message: string;
}

export interface LabRequestState {
  labRequest: any;
  labRequests: Array<any>;
  laboratory: any;
  laboratories: Array<any>;
  laboratoryInvestigations: Array<any>;
  isLabRequestLoading: boolean;
  isLabRequestsLoading: boolean;
  isLaboratoriesLoading: boolean;
  isLaboratoryLoading: boolean;
  isLaboratoryInvestigationsLoading: boolean;
  isCreateLabRequestLoading: boolean;
  hasCreatedRequest: boolean;
  isCancelServiceOrderLoading: boolean;
  isCancelLabRequestLoading: boolean;
  labRequestError: LabRequestError;
  labRequestsError: LabRequestError;
  laboratoriesError: LabRequestError;
  laboratoryError: LabRequestError;
  laboratoryInvestigationsError: LabRequestError;
  createLabRequestError: LabRequestError;
  cancelServiceOrderError: LabRequestError;
  cancelLabRequestError: LabRequestError;
}

export const initialState: LabRequestState = {
  labRequest: null,
  labRequests: [],
  laboratory: null,
  laboratories: [],
  laboratoryInvestigations: [],
  isLabRequestLoading: true,
  isLabRequestsLoading: true,
  isLaboratoriesLoading: false,
  isLaboratoryLoading: false,
  isLaboratoryInvestigationsLoading: false,
  isCreateLabRequestLoading: false,
  hasCreatedRequest: false,
  isCancelServiceOrderLoading: false,
  isCancelLabRequestLoading: false,
  labRequestError: { message: "" },
  labRequestsError: { message: "" },
  laboratoriesError: { message: "" },
  laboratoryError: { message: "" },
  laboratoryInvestigationsError: { message: "" },
  createLabRequestError: { message: "" },
  cancelServiceOrderError: { message: "" },
  cancelLabRequestError: { message: "" },
};

export const labRequestsSlice = createSlice({
  name: "labRequests",
  initialState,
  reducers: {
    // FETCH SINGLE LAB REQUEST
    fetchLabRequestLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLabRequestLoading = payload;
    },
    fetchLabRequestSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.labRequest = payload;
    },
    fetchLabRequestFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.labRequestError = payload;
      state.labRequest = null;
    },
    // FETCH ALL LAB REQUESTS
    fetchLabRequestsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLabRequestsLoading = payload;
    },
    fetchLabRequestsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.labRequests = payload;
    },
    fetchLabRequestsFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.labRequestsError = payload;
    },
    // FETCH ALL LAB REQUESTS
    createLabRequestLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateLabRequestLoading = payload;
      state.hasCreatedRequest = false;
    },
    createLabRequestSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.labRequest = payload;
      state.hasCreatedRequest = true;
    },
    createLabRequestFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.createLabRequestError = payload;
      state.hasCreatedRequest = false;
    },
    // FETCH ALL LABORATORIES
    fetchLaboratoriesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLaboratoriesLoading = payload;
    },
    fetchLaboratoriesSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.laboratories = payload;
    },
    fetchLaboratoriesFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.laboratoriesError = payload;
    },
    // FETCH SINGLE LABORATORIES
    fetchLaboratoryLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLaboratoryLoading = payload;
    },
    fetchLaboratorySuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.laboratory = payload;
    },
    fetchLaboratoryFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.laboratoryError = payload;
    },
    // FETCH ALL LABORATORY INVESTIGATIONS
    fetchLaboratoryInvestigationsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLaboratoryInvestigationsLoading = payload;
    },
    fetchLaboratoryInvestigationsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.laboratoryInvestigations = payload;
    },
    fetchLaboratoryInvestigationsFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.laboratoryInvestigationsError = payload;
    },
    // CANCEL A SERVICE ORDER
    cancelServiceOrderLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isCancelServiceOrderLoading = payload;
    },
    cancelServiceOrderFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.cancelServiceOrderError = payload;
    },
    // CANCEL A LAB REQUEST
    cancelLabRequestLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isCancelLabRequestLoading = payload;
    },
    cancelLabRequestFailed: (
      state,
      { payload }: PayloadAction<LabRequestError>
    ) => {
      state.cancelLabRequestError = payload;
    },
  },
});

export const {
  createLabRequestLoading,
  createLabRequestSuccess,
  createLabRequestFailed,
  fetchLabRequestLoading,
  fetchLabRequestSuccess,
  fetchLabRequestFailed,
  fetchLabRequestsLoading,
  fetchLabRequestsSuccess,
  fetchLabRequestsFailed,
  fetchLaboratoriesLoading,
  fetchLaboratoriesSuccess,
  fetchLaboratoriesFailed,
  fetchLaboratoryLoading,
  fetchLaboratorySuccess,
  fetchLaboratoryFailed,
  fetchLaboratoryInvestigationsLoading,
  fetchLaboratoryInvestigationsSuccess,
  fetchLaboratoryInvestigationsFailed,
  cancelServiceOrderLoading,
  cancelServiceOrderFailed,
  cancelLabRequestLoading,
  cancelLabRequestFailed,
} = labRequestsSlice.actions;
export const labRequestSelector = (state: { labRequests: LabRequestState }) =>
  state.labRequests;
export default labRequestsSlice.reducer;

/** Actions */

export const createSingleLabRequest =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(createLabRequestLoading(true));
    await httpPublic
      .post(`/diagnostics/requests`, payload)
      .then((res) => {
        const labRequest = res?.data?.data;
        dispatch(createLabRequestSuccess(labRequest));
        message.success("Request was successfully raised");
        dispatch(fetchAllLabRequests());
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(createLabRequestFailed(message));
      });
    dispatch(createLabRequestLoading(false));
  };

export const fetchSingleLabRequest =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchLabRequestLoading(true));
    await httpPublic
      .get(`/diagnostics/requests/${id}`)
      .then((res) => {
        const labRequest = res?.data?.data;
        dispatch(fetchLabRequestSuccess(labRequest));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(fetchLabRequestFailed(message));
      });
    dispatch(fetchLabRequestLoading(false));
  };

export const fetchAllLabRequests = (): AppThunk => async (dispatch) => {
  dispatch(fetchLabRequestsLoading(true));
  await httpSecret
    .get(`/diagnostics/requests`)
    .then(async (res) => {
      let labRequests =
        res?.data?.data.map((item: any) =>
          Object.assign({}, item, { selected: false })
        ) || [];
      dispatch(fetchLabRequestsSuccess(labRequests));
    })
    .catch((err) => {
      const _message = {
        message: err?.response?.data?.message || "An error occurred",
      };
      dispatch(fetchLabRequestsFailed(_message));
      // message.error(err?.response?.data?.message || "An error occurred");
    });
  dispatch(fetchLabRequestsLoading(false));
};

export const fetchAllOtherServices = (): AppThunk => async (dispatch) => {
  dispatch(fetchLabRequestsLoading(true));
  await httpSecret
    .get(`/services/orders`)
    .then(async (res) => {
      let labRequests =
        res?.data?.data.map((item: any) =>
          Object.assign({}, item, { selected: false })
        ) || [];
      dispatch(fetchLabRequestsSuccess(labRequests));
    })
    .catch((err) => {
      const _message = {
        message: err?.response?.data?.message || "An error occurred",
      };
      dispatch(fetchLabRequestsFailed(_message));
      // message.error(err?.response?.data?.message || "An error occurred");
    });
  dispatch(fetchLabRequestsLoading(false));
};

export const fetchAllLaboratories = (): AppThunk => async (dispatch) => {
  dispatch(fetchLaboratoriesLoading(true));
  await httpPublic
    .get(`/diagnostics/laboratories`)
    .then((res) => {
      const laboratories = res?.data?.data;
      dispatch(fetchLaboratoriesSuccess(laboratories));
    })
    .catch((err) => {
      const _message = {
        message: err?.response?.data?.message || "An error occurred",
      };
      dispatch(fetchLaboratoriesFailed(_message));
      // message.error(err?.response?.data?.message || "An error occurred");
    });
  dispatch(fetchLaboratoriesLoading(false));
};

export const fetchSingleLaboratory =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchLaboratoryLoading(true));
    await httpPublic
      .get(`/diagnostics/laboratories/${id}`)
      .then((res) => {
        const laboratories = res?.data?.data;
        dispatch(fetchLaboratorySuccess(laboratories));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(fetchLaboratoryFailed(_message));
        // message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(fetchLaboratoryLoading(false));
  };

export const fetchAllLaboratoryInvestigations =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchLaboratoryInvestigationsLoading(true));
    await httpPublic
      .get(`/diagnostics/laboratories/${id}`)
      .then((res) => {
        const investigations = res?.data?.data?.investigations;
        dispatch(fetchLaboratoryInvestigationsSuccess(investigations));
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(fetchLaboratoryInvestigationsFailed(_message));
        // message.error(err?.response?.data?.message || "An error occurred");
      });
    dispatch(fetchLaboratoryInvestigationsLoading(false));
  };

export const cancelServiceOrder =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(cancelServiceOrderLoading(true));

    await httpPublic
      .patch(`/services/orders/${id}/cancel`)
      .then((res) => {
        message.success(
          res.data.message || "Service Order successfully cancelled."
        );
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred",
        };

        dispatch(cancelServiceOrderFailed(_message));
      });
    dispatch(cancelServiceOrderLoading(false));
  };

export const cancelLabRequest =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(cancelLabRequestLoading(true));

    await httpPublic
      .patch(`/diagnostics/requests/${id}/cancel`)
      .then((res) => {
        message.success(
          res.data.message || "Lab Request successfully cancelled."
        );
      })
      .catch((err) => {
        const _message = {
          message: err.response.data.message || "An error occured",
        };

        dispatch(cancelLabRequestFailed(_message));
      });
    dispatch(cancelLabRequestLoading(false));
  };
