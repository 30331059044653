import AppIcon from "../../assets/images/icon.svg";
import { NavbarWrapper, MenuLink } from "./styles";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Dropdown, Menu, message, Switch } from "antd";
import { authSelector, logOutProvider, requestActivation } from "../../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import AvatarImg from "../Avatar";
import getAllAPIKeys from "../../utils/getAllAPIKeys";
import { useEffect, useState } from "react";

const Navbar = ({ setOpenedMenu }: any) => {
    const dispatch = useDispatch();
    const { isAccountActivationLoading } = useSelector(authSelector);
    const [mode, setMode] = useState<string | null>("");
    const history = useHistory();
    const location = useLocation();

    const integrationMode = window.localStorage.getItem("integrationMode");
    useEffect(() => {
        setMode(integrationMode)
    }, [integrationMode]);

    
    const profile = JSON.parse(localStorage.getItem('pneumaCurrentUser') || '');
    const { first_name, last_name } = profile || {};

    const handleLogout = () => {
        dispatch(logOutProvider());
    }

    const switchMode = (checked: boolean) => {
        const { liveSecretKey, testSecretKey, livePublicKey, testPublicKey } = JSON.parse(window.localStorage.getItem("accessKeys") || "");

        if (checked && !(liveSecretKey && livePublicKey)) {
            return message.error(`Your live mode has not been activated yet`);
        }

        message.success(`You've switched to ${checked ? 'live' : 'test'} mode`)

        if (checked) {
            window.localStorage.setItem("integrationMode", "live");
            setMode("live");
        } else {
            window.localStorage.setItem("integrationMode", "test");
            setMode("test");
        }

        window.localStorage.setItem("accessPublicKey", checked ? livePublicKey : testPublicKey);
        window.localStorage.setItem("accessSecretKey", checked ? liveSecretKey : testSecretKey);
        window.location.reload();
    }

    const menu = (
        <Menu>
          <Menu.Item key="0">
            <Link to="/manage-account">Manage account</Link>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="1" onClick={handleLogout}>Log out</Menu.Item>
        </Menu>
    );

    return (
        <NavbarWrapper>
            <span style={{display: 'flex', alignItems: 'center'}}>
                <i className="lni lni-menu menu-icon" onClick={() => setOpenedMenu(true)}></i>
                <img src={AppIcon} alt="icon" />
            </span>
            <div className="menu">
                <span className="mode">Live Mode</span>
                <Switch 
                    checked={mode === "live"}
                    onChange={switchMode} 
                />
                <Dropdown overlay={menu} trigger={['click']}>
                    <MenuLink>
                        <span className="username" style={{marginRight: 10}}>{first_name} {last_name}</span>
                        <AvatarImg img="" fallbackText={`${first_name} ${last_name}`} />
                    </MenuLink>
                </Dropdown>
            </div>
            
        </NavbarWrapper>
    )
}

export default Navbar;
