import FormTable from "./containers/FormTable";
import { FormWrapper, ContentWrapper } from "./styles";

const Forms = () => {
    return (
        <FormWrapper>
            <ContentWrapper>
                <FormTable />
            </ContentWrapper>
        </FormWrapper>
    )
}

export default Forms;