import { Col, Modal, Row, Radio } from "antd";
import { DashboardModeWrapper } from "./styles";
import { ReactComponent as EncounterIcon } from "../../assets/icons/doctor.svg";
import { ReactComponent as ServiceIcon } from "../../assets/icons/healthcare.svg";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

const SwitchModeModal = ({ isModalVisible, handleCancel }: any) => {
  const [selected, setSelected] = useState<string>("");
  const dashboardMode = window.localStorage.getItem("pneumaDashboardMode");
  const history = useHistory();

  useEffect(() => {
      if (dashboardMode) {
        setSelected(dashboardMode);
      }
  }, [dashboardMode]);

  const modes = [
    {
      id: "connect",
      icon: <ServiceIcon />,
      title: "Connect Dashboard",
      description: "Earn more by navigating your customers to the best care.",
    },
    {
      id: "integration",
      icon: <EncounterIcon />,
      title: "Integration Dashboard",
      description: "Build, launch & integrate digital health apps and services.",
    },
  ];

  const handleUpdate = () => {
    window.localStorage.setItem("pneumaDashboardMode", selected);
    // history.replace('/overview');
    window.location.replace('/overview');
    handleCancel();
  }

  return (
    <Modal
      title="Select Dashboard Mode"
      okText="Switch mode"
      className="dashboard-mode-modal"
      visible={isModalVisible}
      onOk={handleUpdate}
      onCancel={handleCancel}
    >
      <DashboardModeWrapper>
        <Row align="middle" gutter={30}>
          {modes.map(({ id, icon, title, description }, index) => {
            return (
              <Col span={12}>
                <div
                  className={`mode-item ${id === selected ? "active" : ""}`}
                  onClick={() => setSelected(id)}
                >
                  <div className="check">
                    <Radio checked={id === selected} />
                  </div>
                  <span className="icon">{icon}</span>
                  <h3>{title}</h3>
                  <p>{description}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </DashboardModeWrapper>
    </Modal>
  );
};

export default SwitchModeModal;
