import styled from 'styled-components';

const UploadMainWrapper = styled.div`
    margin-bottom: 10px;
    .flex {
        background: #f2f2f2;
        border: none;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
    }
`;

const UploadWrapper = styled.div`
    margin-bottom: 0px;
    .ant-upload {
        padding: 0px 20px !important;
        &.ant-upload-drag {
            border: 1px solid transparent;
            border-radius: 0px;
            &:hover {
                border: 1px solid transparent;
            }
        }
        .flex {
            display: flex;
            justify-content: space-between;
            span {
                flex: 1;
            }
        }
    }
    .ant-upload.ant-upload-drag p.ant-upload-hint {
        font-size: 12px;
        margin-top: 5px;
    }
    p {
        font-size: 13px;
        margin-bottom: 5px;
    }
`;

const PreviewWrapper = styled.div`
    padding: 7px 10px;
    background: #fff;
    font-size: 13px;
    color: #0f9af0;
    text-decoration: underline;
`;

export { UploadMainWrapper, UploadWrapper, PreviewWrapper }