import { useState } from "react";
import { Col, Row, Form, Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import AppIcon from "../../../assets/images/icon.svg";
import Button from "../../../component/Button";
import Input from "../../../component/Input";
import RegisterSvg from "../../../assets/icons/register-icon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";

import ValidCheckSvg from "../../../assets/icons/valid-icon.svg";
import InvalidCheckSvg from "../../../assets/icons/invalid-icon.svg";
import { authSelector, registerProvider } from "../../../redux/reducers/auth";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { ErrorMsg } from "../../../layouts/OnboardingLayout/styles";

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] =
    useState(false);
  const [primaryLoginChoice, setPrimaryLoginChoice] = useState("email");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [payload, setPayload] = useState<any>({});

  const { isLoading, error } = useSelector(authSelector);

  const steps = [
    {
      key: "developer_information",
      component: (props: any) =>
        props.isVisible && (
          <>
            <h3 style={{ marginBottom: "2em" }}>Developer Information</h3>

            {/* first and last names */}
            <Row gutter={20} style={{ width: "100%" }}>
              <Col lg={12} xs={24} style={{ marginBottom: "2em" }}>
                <Input
                  formItem={{
                    label: "What is your first name?",
                    name: "first_name",
                    rules: [
                      {
                        required: true,
                        message: "Please input your first name",
                      },
                    ],
                  }}
                  placeholder='First Name'
                  type='text'
                  marginBottom={0}
                  label='What is your first name?'
                />
              </Col>

              <Col lg={12} xs={24} style={{ marginBottom: "2em" }}>
                <Input
                  formItem={{
                    label: "What is your last name?",
                    name: "last_name",
                    rules: [
                      {
                        required: true,
                        message: "Please input your last name",
                      },
                    ],
                  }}
                  placeholder='Last Name'
                  type='text'
                  marginBottom={0}
                  label='What is your last name?'
                />
              </Col>
            </Row>

            {/* phone number and date of birth */}
            <Row gutter={20} style={{ width: "100%" }}>
              <Col lg={12} xs={24} style={{ marginBottom: "1em" }}>
                <Input
                  formItem={{
                    label: "Phone number?",
                    name: "phone",
                    rules: [
                      {
                        required: true,
                        message: "Please input your phone number",
                      },
                    ],
                  }}
                  className='phone-input'
                  placeholder='08012345678'
                  marginBottom={0}
                  label='What is your phone number?'
                />
              </Col>
            </Row>
          </>
        ),
    },
    {
      key: "business_information",
      component: (props: any) =>
        props.isVisible && (
          <>
            <h3 style={{ marginBottom: "2em" }}>Business Information</h3>
            <Row gutter={20} style={{ width: "100%" }}>
              <Col lg={12} xs={24} style={{ marginBottom: "2em" }}>
                <Input
                  formItem={{
                    label: "Business Name?",
                    name: "business_name",
                    rules: [
                      {
                        required: true,
                        message: "Please input your business name",
                      },
                    ],
                  }}
                  placeholder='Business Name'
                  type='text'
                  marginBottom={0}
                  label='What is your business name?'
                />
              </Col>

              <Col lg={12} xs={24} style={{ marginBottom: "2em" }}>
                <Input
                  formItem={{
                    label: "Email address?",
                    name: "email",
                    rules: [
                      { required: true, message: "Please input your email" },
                    ],
                    tooltip: {
                      title:
                        "This helps us provide relevant information for you.",
                      icon: <InfoCircleOutlined />,
                    },
                  }}
                  placeholder='name@mail.com'
                  type='email'
                  marginBottom={0}
                  label='What is your email address?'
                />
              </Col>
            </Row>

            <Row gutter={20} style={{ width: "100%" }}>
              <Col sm={16} xs={24}>
                <Input
                  formItem={{
                    label: "Street Address",
                    name: "street_line_one",
                    rules: [
                      {
                        required: true,
                        message: "Please input your Street Address",
                      },
                    ],
                  }}
                  placeholder='Street Address'
                  type='text'
                  label='What is your Street Address?'
                />
              </Col>

              <Col sm={8} xs={12}>
                <Input
                  formItem={{
                    label: "Postal Code",
                    name: "post_code",
                    rules: [
                      {
                        required: true,
                        message: "Please input your Postal Code",
                      },
                    ],
                  }}
                  placeholder='900101'
                  type='text'
                  label='What is your Postal Code?'
                />
              </Col>

              <Col xs={12} sm={0}>
                <Input
                  formItem={{
                    label: "City",
                    name: "city",
                    rules: [
                      { required: true, message: "Please input your City" },
                    ],
                  }}
                  placeholder='City'
                  type='text'
                  marginBottom={0}
                  label='What is your City?'
                />
              </Col>
            </Row>

            <Row gutter={20} style={{ width: "100%" }}>
              <Col sm={8} xs={0}>
                <Input
                  formItem={{
                    label: "City",
                    name: "city",
                    rules: [
                      { required: true, message: "Please input your City" },
                    ],
                  }}
                  placeholder='City'
                  type='text'
                  marginBottom={0}
                  label='What is your City?'
                />
              </Col>

              <Col xs={12} sm={8}>
                <Input
                  formItem={{
                    label: "State",
                    name: "state",
                    rules: [
                      { required: true, message: "Please input your State" },
                    ],
                  }}
                  placeholder='State'
                  type='text'
                  marginBottom={0}
                  label='What is your State?'
                />
              </Col>
              <Col xs={12} sm={8}>
                <Input
                  formItem={{
                    label: "Country",
                    name: "country",
                    rules: [
                      { required: true, message: "Please input your Country" },
                    ],
                  }}
                  placeholder='Country'
                  type='text'
                  marginBottom={0}
                  label='What is your Country?'
                />
              </Col>
            </Row>
          </>
        ),
    },
    {
      key: "password",
      component: (props: any) => {
        const content = (
          <div>
            <p className='title'>Password should contain</p>
            <p>
              <img
                src={
                  /.{8,}/.test(props.password) ? ValidCheckSvg : InvalidCheckSvg
                }
                alt='valid/not icon'
              />{" "}
              8 or more characters{" "}
            </p>
            <p>
              <img
                src={
                  /(?=.*?[a-z])/.test(props.password)
                    ? ValidCheckSvg
                    : InvalidCheckSvg
                }
                alt='valid/not icon'
              />{" "}
              At least one lower case letter{" "}
            </p>
            <p>
              <img
                src={
                  /(?=.*?[A-Z])/.test(props.password)
                    ? ValidCheckSvg
                    : InvalidCheckSvg
                }
                alt='valid/not icon'
              />{" "}
              At least one capital letter{" "}
            </p>
            <p>
              <img
                src={
                  /(?=.*?[0-9])/.test(props.password)
                    ? ValidCheckSvg
                    : InvalidCheckSvg
                }
                alt='valid/not icon'
              />{" "}
              At least one number{" "}
            </p>
            <p>
              <img
                src={
                  /(?=.*?[#?!@$%^&*-])/.test(props.password)
                    ? ValidCheckSvg
                    : InvalidCheckSvg
                }
                alt='valid/not icon'
              />{" "}
              At least one symbol
            </p>
          </div>
        );
        return (
          props.isVisible && (
            <Col span={24}>
              <Input
                formItem={{
                  label: "Create a password",
                  name: "password",
                  validateTrigger: ["onChange", "onBlur"],
                  rules: [
                    { required: true, message: "Please input your password" },
                    {
                      pattern:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                      message:
                        "Password must contain atleast one uppercase, one lowercase, a number, and a special character",
                    },
                  ],
                }}
                hasHintPopoverContent={props.passwordFocused}
                hintPopoverContent={content}
                onChange={(e: any) => props.setPassword(e.target.value)}
                onFocus={() => props.setPasswordFocused(true)}
                onBlur={() => props.setPasswordFocused(false)}
                placeholder='password'
                type='password'
                label='Create a password'
              />
              <Input
                formItem={{
                  label: "Confirm password",
                  name: "confirm_password",
                  validateTrigger: ["onChange", "onBlur"],
                  rules: [
                    { required: true, message: "Please confirm your password" },
                    {
                      pattern: props.password,
                      message: "Password does not match",
                    },
                  ],
                }}
                placeholder='password'
                type='password'
                marginBottom={10}
                label='Confirm password'
              />
              <span
                className='terms-conditions-text'
                style={{ marginBottom: "1em" }}
              >
                <Checkbox
                  checked={acceptedTerms}
                  onChange={() => setAcceptedTerms((prev) => !prev)}
                />
                Accept Terms and Conditions
              </span>
              <Row>
                <Col span={24}>
                  {!isLoading && (
                    <ErrorMsg className='error-msg'>{error?.message}</ErrorMsg>
                  )}
                </Col>
              </Row>
            </Col>
          )
        );
      },
    },
  ];

  const parsePayload = (password: string) => {
    const { street_line_one, city, state, country, post_code, dob } =
      payload || {};

    setPayload({ ...payload, password });
    const newPayload: any = _.pick(payload, [
      "first_name",
      "last_name",
      "business_name",
      "email",
      "gender",
      "phone",
    ]);
    newPayload.password = password;
    newPayload.dob = moment(dob).format("DD/MM/yyyy");
    newPayload.address = {
      street_line_one,
      city,
      state,
      country,
      post_code,
    };
    return newPayload;
  };

  const handleRegister = async (values: any) => {
    const _payload = { ...payload };
    setPayload({ ..._payload, ...values });
    if (currentIndex !== steps.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      const finalPayload = parsePayload(values.password);
      await dispatch(registerProvider(finalPayload));
      // !error.message && setIsRegistrationSuccessful(true);
    }
  };

  // return isRegistrationSuccessful ? <EmailLinkSent setIsRegistrationSuccessful={setIsRegistrationSuccessful} /> : (
  return (
    <>
      <Col xs={0} md={12}>
        <div className='left-pane'>
          <img className='app-logo' src={AppIcon} alt='logo' />
          <div className='align-center'>
            <img src={RegisterSvg} alt='icon' />
            <h3>Create an account</h3>
            <p>Enter your details to create an account</p>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className='right-pane'>
          <span className='close-icon' onClick={() => history.goBack()}>
            <i className='lni lni-arrow-left' /> Back
          </span>
          <Form
            name='basic'
            layout='vertical'
            requiredMark={false}
            onFinish={handleRegister}
            style={{ marginTop: "2em" }}
          >
            <Row gutter={24} align='middle'>
              {steps.map((step, index) => {
                const isVisible = index === currentIndex;
                const { component, key } = step;
                const componentProps =
                  key === "developer_information"
                    ? {
                        isVisible,
                        gender,
                        setGender,
                        primaryLoginChoice,
                        setPrimaryLoginChoice,
                      }
                    : key === "password"
                    ? {
                        isVisible,
                        password,
                        setPassword,
                        passwordFocused,
                        setPasswordFocused,
                      }
                    : { isVisible };
                return component(componentProps);
              })}
              <Col
                span={24}
                className={`align-center ${
                  currentIndex === 0 ? "justify-end" : "justify-between"
                }`}
                style={{ marginTop: 20 }}
              >
                {currentIndex !== 0 && (
                  <Button
                    onClick={() => setCurrentIndex(currentIndex - 1)}
                    type='secondary'
                    className='auth-button prev-button'
                  >
                    Previous
                  </Button>
                )}
                {currentIndex === steps.length - 1 ? (
                  <Button
                    htmlType='submit'
                    type='primary'
                    className='auth-button'
                    style={{ marginRight: 10 }}
                    disabled={!acceptedTerms}
                  >
                    {isLoading ? "Creating Account..." : "Create Account"}
                  </Button>
                ) : (
                  <Button
                    htmlType='submit'
                    type='primary'
                    className='auth-button next-button'
                  >
                    Next
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default Register;
