import styled from 'styled-components';

const FormTableWrapper = styled.div`
    .input-pane {
        padding: 10px 10px;
        border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
        input {
            height: 45px;
            border-radius: 6px;
        }
    }
`;

interface FormItemWrapperProps {
    index: number;
}

const FormItemWrapper = styled.div<FormItemWrapperProps>`
    padding: 20px;
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
    ${props => props.index % 2 !== 0 && `background: #0f9af01a;`}
    &:last-child {
        border-bottom: none;
    }
    h3 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0px;
    }
    p {
        margin-top: 8px;
        font-size: 13px;
        margin-bottom: 0px;
    }
    i {
        font-size: 13px;
        font-style: italic;
        margin-bottom: 0px;
    }
    .tag {
        background: #1890ff;
        padding: 5px 10px;
        display: inline-block;
        margin-bottom: 0px;
        margin-top: 10px;
        text-transform: uppercase;
        font-family: "DM Sans";
        word-wrap: break-all; 
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #fff;
        opacity: 0.75;
        border-radius: 10px;
    }
    .btn-questions {
        @media(max-width: 767px) {
            margin-top: 15px;
        }
    }
`;

const FormTableHeader = styled.div`
    height: auto;
    display: flex;
    align-items: center;
    ul {
        list-style-type: none;
        list-style: none;
        margin: 0;
        padding: 0px 40px;
        li {
            display: block;
            float: left;
            padding: 20px 10px 10px 10px;
            margin-right: 44px;
            cursor: pointer;
            font-family: "DM Sans";
            font-weight: 500;
            font-size: 13px;
            text-align: left;
            color: #002e46;
            &.active {
                color: #0f9af0;
                border-bottom: 3px solid #0f9af0;
            }
        }
    }
`;

const FormTableBody = styled.div`
    .ant-table {
        .ant-table-thead > tr > th {
            background: #f3f8fb;
            /* border-top: 0.5px solid rgba(78, 102, 126, 0.35); */
            border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
        }
        .ant-table-tbody > tr > td {
            background: #fff;
        }
        tr {
            td:first-child, th:first-child {
                padding: 10px 40px;
            }
        }
        th.ant-table-cell {
            font-family: "DM Sans";
            font-weight: bold;
            font-size: 13px;
            line-height: 24px;
            text-align: left;
            color: #002e46;
            opacity: 0.75;
        }
        td.ant-table-cell {
            font-family: "DM Sans";
            font-weight: normal;
            font-size: 13px;
            line-height: 14px;
            text-align: left;
            color: #002e46;
            opacity: 0.75;
            .flex {
                display: flex;
                img {
                    margin-right: 10px;
                    border: 0.8px solid #0f9af0;
                    border-radius: 50%;
                    height: 100%;
                    padding: 2px;
                }
            }
            .tag {
                background: #C1EEE4;
                padding: 5px 10px;
                font-family: "DM Sans";
                font-weight: 500;
                font-size: 13px;
                line-height: 14px;
                text-align: center;
                color: #0ABB94;
                opacity: 0.75;
                border-radius: 10px;
            }
            .action {
                img {
                    margin-left: 10px;
                }
            }
            p {
                margin-bottom: 5px;
            }
            span {
                font-family: "DM Sans";
                font-weight: normal;
                font-size: 13px;
                text-align: left;
                color: #0f9af0;
                opacity: 0.75;
            }
        }
    }
`;

export { FormTableWrapper, FormTableHeader, FormTableBody, FormItemWrapper }