import { DatePicker as AntdDatePicker, Form } from "antd"
import { DatePickerWrapper } from "./styles";

const DatePicker = (props: any) => {
    const { formItem, mode, ...rest } = props;
    return (
        <DatePickerWrapper mode={mode}>
            <Form.Item {...formItem}>
                <AntdDatePicker {...rest} />
            </Form.Item>
        </DatePickerWrapper>
    )
}

export default DatePicker;