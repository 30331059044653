import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Tabs, Empty, Pagination } from "antd";
import { capitalize } from "lodash";

import { ReactComponent as BackIcon } from "assets/icons/back-icon.svg";
import noAvailablePlans from "assets/services-unavailable-providers.gif";

import Button from "component/Button";
import AddProviderModal from "component/AddProviderModal";
import RemoveProviderModal from "component/RemoveProviderModal";

// redux
import {
  fetchSingleProvider,
  fetchAllMyProviders,
  providersSelector
} from "redux/reducers/providers";

import { OtherWrapper, Wrapper, Header, PlansGridWrapper } from "./styles";
import AvatarImg from "component/Avatar";

function SingleProvider() {
  const params: { providerId: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { providerId } = params;

  const {
    singleProvider,
    singleProviderError,
    isSingleProviderLoading,
    myProviders,
    isMyProvidersLoading
  } = useSelector(providersSelector);

  const [currentPage, setCurrentPage] = useState(1);
  const [plansPerPage, setPlansPerPage] = useState(10);

  let filteredPlans = singleProvider
    ? singleProvider.service_plans.filter((provider) => provider.public)
    : [];

  // Logic for displaying current paginated plans
  const indexOfLastPlan = currentPage * plansPerPage;
  const indexOfFirstPlan = indexOfLastPlan - plansPerPage;
  const currentPlans = filteredPlans?.slice(indexOfFirstPlan, indexOfLastPlan);

  useEffect(() => {
    dispatch(fetchSingleProvider(providerId));
  }, [dispatch, providerId]);

  useEffect(() => {
    dispatch(fetchAllMyProviders());
  }, [dispatch]);

  const activeProviders = myProviders.filter(
    (provider) => provider.status === "in_network"
  );

  const isMyProvider = activeProviders.find(
    (provider) => provider.service_provider.id === providerId
  );

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  // formatted price
  function formatPrice(price: number) {
    return price / 100 > 1000 ? `₦ ${(price / 100).toLocaleString()}` : "Free";
  }

  if (singleProviderError.message) {
    return (
      <OtherWrapper>
        <h5>
          {singleProviderError.message || "Something went wrong."} Please try
          again
        </h5>
      </OtherWrapper>
    );
  }

  if (isSingleProviderLoading || isMyProvidersLoading) {
    return (
      <OtherWrapper>
        <div>
          <Spin />
          <h5>Loading...</h5>
        </div>
      </OtherWrapper>
    );
  }

  return (
    <>
      <Wrapper>
        <div className='container'>
          <span onClick={() => history.goBack()} className='back-btn'>
            <BackIcon />
            Go back
          </span>

          <Header>
            <div className='provider-details'>
              <div className='name'>
                <AvatarImg
                  size='large'
                  fallbackText={`${singleProvider?.business_name
                    .split(" ")[0]
                    .charAt(0)
                    .toUpperCase()}

                  ${
                    singleProvider?.business_name.split(" ")[1]
                      ? singleProvider?.business_name
                          .split(" ")[1]
                          .charAt(0)
                          .toUpperCase()
                      : ""
                  }
                `}
                />

                <h2>{singleProvider?.business_name}</h2>
              </div>

              <p className='description'>{singleProvider?.description}</p>

              <div className='details'>
                <div className='single-detail'>
                  <h4>Email address</h4>
                  <p>{singleProvider?.email}</p>
                </div>

                <div className='single-detail'>
                  <h4>Phone number</h4>
                  <p>{singleProvider?.phone}</p>
                </div>

                {singleProvider?.website_url ? (
                  <div className='single-detail'>
                    <h4>Website</h4>
                    <a
                      href={singleProvider.website_url}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {singleProvider.website_url}
                    </a>
                  </div>
                ) : null}

                <div className='single-detail'>
                  <h4>State</h4>
                  <p>{singleProvider?.address.state}</p>
                </div>

                <div className='single-detail'>
                  <h4>City</h4>
                  <p>{capitalize(singleProvider?.address.city)}</p>
                </div>

                {singleProvider?.address.street_line_one ? (
                  <div className='single-detail'>
                    <h4>Address</h4>
                    <p>{singleProvider.address.street_line_one}</p>
                  </div>
                ) : null}
              </div>
            </div>

            <Button
              className={isMyProvider ? "remove-btn" : "add-btn"}
              onClick={() => {
                if (isMyProvider) {
                  setShowRemoveModal(true);
                } else {
                  setShowAddModal(true);
                }
              }}
            >
              {isMyProvider ? "Remove provider" : "Add to network"}
            </Button>
          </Header>

          <Tabs
            size='large'
            items={[
              {
                label: "Service Plans",
                key: "service-plans",
                children: (
                  <>
                    {filteredPlans?.length > 0 ? (
                      <PlansGridWrapper>
                        {currentPlans?.map((plan) => (
                          <div key={plan.id} className='single-plan'>
                            <div>
                              <h5>{plan.name}</h5>
                              <p>{plan.schedule}</p>
                            </div>

                            <p className='price'>
                              {plan.price ? formatPrice(+plan.price) : "Free"}
                            </p>
                          </div>
                        ))}
                      </PlansGridWrapper>
                    ) : (
                      <Empty
                        image={noAvailablePlans}
                        imageStyle={{ height: 150 }}
                        description={
                          <h3>
                            This provider currently offers no service plans
                          </h3>
                        }
                      />
                    )}

                    {filteredPlans?.length > 0 && (
                      <Pagination
                        total={filteredPlans?.length}
                        style={{ marginTop: "2em", float: "right" }}
                        defaultPageSize={plansPerPage}
                        onChange={(number) => setCurrentPage(number)}
                        onShowSizeChange={(current, size) =>
                          setPlansPerPage(size)
                        }
                        showSizeChanger
                        pageSizeOptions={["10", "20", "30"]}
                      />
                    )}
                  </>
                )
              }
            ]}
          />
        </div>
      </Wrapper>

      <AddProviderModal
        handleCancel={() => setShowAddModal(false)}
        isModalVisible={showAddModal}
        selectedProvider={{
          name: singleProvider?.business_name!,
          id: providerId
        }}
      />

      <RemoveProviderModal
        handleCancel={() => setShowRemoveModal(false)}
        isModalVisible={showRemoveModal}
        selectedProvider={{
          name: singleProvider?.business_name!,
          id: providerId
        }}
      />
    </>
  );
}

export default SingleProvider;
