import { Col, Modal, Row, Select as AntdSelect, Form, message } from "antd";
import { AddUserModalWrapper } from "./styles";
import Input from "../Input";
import Select from "../Select";
import { useDispatch, useSelector } from "react-redux";
import {
  approveUserAccessRequest,
  createUser,
  requestUserAccess,
  resetHasVerifiedUser,
  userSelector,
  verifyUser,
} from "../../redux/reducers/users";
import Button from "../Button";
import DatePicker from "../DatePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";

const AddUserModal = ({ isModalVisible, handleCancel }: any) => {
  const dispatch = useDispatch();
  const {
    isCreateUserLoading,
    hasCreatedUser,
    hasVerifiedUser,
    isVerifyUserLoading,
  } = useSelector(userSelector);
  const [userForm] = Form.useForm();
  const [email, setEmail] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    dispatch(resetHasVerifiedUser());
  }, [dispatch]);

  useEffect(() => {
    if (hasCreatedUser) {
      userForm.resetFields();
      setEmail("");
      setCurrentIndex(0);
      setOtp("");
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCreatedUser]);

  useEffect(() => {
    if (hasVerifiedUser === null) {
      setCurrentIndex(0);
    } else if (hasVerifiedUser) {
      handleRequestAccess();
    } else {
      setCurrentIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hasVerifiedUser]);

  const handleRequestAccess = async() => {
    await dispatch(requestUserAccess({ email }));
    setCurrentIndex(2);
  }

  const handleVerifyUser = async (values: any) => {
    const payload = {
      email: values.email,
    };
    await dispatch(verifyUser(payload));
  };

  const handleOnboardUser = async (values: any) => {
    if (otp) {
      dispatch(approveUserAccessRequest(otp));
    } else {
      message.error("Please enter the OTP code");
    }
  };

  const handleCreateUser = async (values: any) => {
    const payload = values;
    if (values.dob) {
      payload.dob = moment(values.dob).format("DD/MM/YYYY");
    }
    payload.email = email;
    await dispatch(createUser(payload));
  };

  const steps = [
    {
      component: (props: any) =>
        props.isVisible && (
          <Row gutter={24}>
            <Col span={24}>
              <Input
                formItem={{
                  label: "Email address",
                  name: "email",
                  rules: [
                    {
                      required: true,
                      message: "Please input user's Email address",
                    },
                    {
                      type: "email",
                      message: "Please input a valid email",
                    },
                  ],
                }}
                onChange={(e: any) => setEmail(e.target.value)}
                mode="normal"
                type="email"
                label="Email address"
              />
            </Col>
          </Row>
        ),
    },
    {
      component: (props: any) =>
        props.isVisible && (
          <div>
            <p
              style={{
                color: "#333",
                lineHeight: "22px",
                fontSize: "13px",
                background: "#0ff0c07a",
                padding: "10px 20px",
                borderRadius: "6px",
                marginBottom: '20px'
              }}
            >
              Create a profile for {email}
            </p>
            {/* <Alert message= type="success" /> */}
            <Row gutter={24}>
              <Col span={12}>
                <Input
                  formItem={{
                    label: "First Name",
                    name: "first_name",
                    rules: [
                      {
                        required: true,
                        message: "Please input user's first name",
                      },
                    ],
                  }}
                  mode="normal"
                  type="text"
                />
              </Col>
              <Col span={12}>
                <Input
                  formItem={{
                    label: "Last Name",
                    name: "last_name",
                    rules: [
                      {
                        required: true,
                        message: "Please input user's last name",
                      },
                    ],
                  }}
                  mode="normal"
                  type="text"
                />
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Input
                  formItem={{
                    label: "Phone number",
                    name: "phone",
                    rules: [
                      {
                        required: true,
                        message: "Please enter user's phone number",
                      },
                      {
                        min: 11,
                        message:
                          "Phone number cannot be less than 11 characters",
                      },
                      {
                        max: 11,
                        message:
                          "Phone number cannot be greater than 11 characters",
                      },
                      {
                        pattern: /^\d+$/,
                        message: "Phone number can only contain digits",
                      },
                    ],
                  }}
                  mode="normal"
                  type="text"
                  label="Phone number"
                />
              </Col>
              <Col span={12}>
                <Select
                  formItem={{
                    name: "gender",
                    label: "Gender",
                    rules: [
                      {
                        required: true,
                        message: "Please input user's gender",
                      },
                    ],
                  }}
                  marginBottom={0}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select user's gender"
                  label=""
                  mode="normal"
                  required
                >
                  {["male", "female"].map((option, index) => {
                    return (
                      <AntdSelect.Option key={index} value={option}>
                        <span style={{textTransform: 'capitalize'}}>{option}</span>
                      </AntdSelect.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <DatePicker
                  formItem={{
                    label: "Date of Birth",
                    name: "dob",
                    rules: [
                      {
                        required: true,
                        message: "Please input your Date of Birth",
                      },
                    ],
                  }}
                  mode="normal"
                  format="DD/MM/yyyy"
                  type="text"
                />
              </Col>
            </Row>
          </div>
        ),
    },
    {
      component: (props: any) =>
        props.isVisible && (
          <div className="otp-pane">
            <p
              style={{
                color: "#333",
                lineHeight: "22px",
                fontSize: "13px",
                background: "#0ff0c07a",
                padding: "10px 20px",
                borderRadius: "6px",
                marginBottom: '20px'
              }}
              >
              An OTP code has been sent to {email}
            </p>
            <label style={{textAlign: 'left'}}>Enter OTP code</label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={8}
                containerStyle={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
                inputStyle={{
                  height: "40px",
                  width: "40px",
                  border: "2px solid rgb(223 218 218)",
                  borderRadius: "6px",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
                focusStyle={{
                  border: "2px solid #0f9af0",
                }}
              />
            </div>
          </div>
        ),
    },
  ];

  const formSubmitFn =
    hasVerifiedUser === null
      ? handleVerifyUser
      : hasVerifiedUser
      ? handleOnboardUser
      : handleCreateUser;

  const secondaryButtonText = hasVerifiedUser === null ? "Cancel" : "Back";

  const secondaryButtonOnClick =
    hasVerifiedUser === null
      ? handleCancel
      : () => {
          setCurrentIndex(0);
          dispatch(resetHasVerifiedUser());
        };

  const mainButtonText =
    hasVerifiedUser === null
      ? "Verify email"
      : hasVerifiedUser
      ? "Add user"
      : "Create new user";

  const mainButtonLoadingText =
    hasVerifiedUser === null
      ? "Verifying..."
      : hasVerifiedUser
      ? "Onboarding..."
      : "Creating...";

  const loading =
    hasVerifiedUser === null
      ? isVerifyUserLoading
      : hasVerifiedUser
      ? isCreateUserLoading
      : isCreateUserLoading;

  const isButtonDisabled = hasVerifiedUser && !otp;

  return (
    <Modal
      title="Add new user"
      okText="Submit"
      footer={null}
      className="dashboard-mode-modal"
      visible={isModalVisible}
      onCancel={handleCancel}
    >
      <AddUserModalWrapper>
        <Form
          name="basicForm"
          form={userForm}
          layout="vertical"
          onFinish={formSubmitFn}
        >
          {steps.map((step, index) => {
            const isVisible = index === currentIndex;
            const { component } = step;
            return component({ isVisible });
          })}
          <Row justify="end" style={{ marginTop: "20px" }}>
            <Button
              type="secondary"
              onClick={secondaryButtonOnClick}
              style={{ marginRight: 10 }}
            >
              {secondaryButtonText}
            </Button>
            <Button htmlType="submit" type="primary" disabled={isButtonDisabled}>
              {loading ? mainButtonLoadingText : mainButtonText}
            </Button>
          </Row>
        </Form>
      </AddUserModalWrapper>
    </Modal>
  );
};

export default AddUserModal;
