import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http, { httpSecret } from '../../utils/api'
import { message } from 'antd'

export interface ReviewError {
    message: string
}

export interface ReviewState {
    review: any
    reviews: any
    isReviewLoading: boolean
    isReviewsLoading: boolean
    reviewError: ReviewError
    reviewsError: ReviewError
}

export const initialState: ReviewState = {
    review: null,
    reviews: null,
    isReviewLoading: true,
    isReviewsLoading: true,
    reviewError: { message: '' },
    reviewsError: { message: '' }
}

export const reviewsSlice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        // FETCH SINGLE REVIEW
        fetchReviewLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isReviewLoading = payload
        },
        fetchReviewSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.review = payload;
        },
        fetchReviewFailed: (state, { payload }: PayloadAction<ReviewError>) => {
            state.reviewError = payload;
            state.review = null;
        },
        // FETCH ALL REVIEWS
        fetchReviewsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isReviewsLoading = payload
        },
        fetchReviewsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.reviews = payload;
        },
        fetchReviewsFailed: (state, { payload }: PayloadAction<ReviewError>) => {
            state.reviewsError = payload;
        },
    },
})

export const { 
    fetchReviewLoading, fetchReviewSuccess, fetchReviewFailed, 
    fetchReviewsLoading, fetchReviewsSuccess, fetchReviewsFailed, 
} = reviewsSlice.actions;
export const reviewSelector = (state: { reviews: ReviewState }) => state.reviews;
export default reviewsSlice.reducer;


/** Actions */

export const fetchSingleReview = (id: any): AppThunk => async (dispatch) => {
    dispatch(fetchReviewLoading(true))
    await httpSecret.get(`/consults_ratings/${id}`)
        .then((res) => {
            const review= res?.data?.data;
            dispatch(fetchReviewSuccess(review))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchReviewFailed(message));
        })
    dispatch(fetchReviewLoading(false));
}

export const fetchAllReviews = (): AppThunk => async (dispatch) => {
    dispatch(fetchReviewsLoading(true))
    await httpSecret.get(`/consults_ratings`)
        .then((res) => {
            const reviews = res?.data?.data;
            dispatch(fetchReviewsSuccess(reviews));
            // message.success("Reviews Fetched Successfully");
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(fetchReviewsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(fetchReviewsLoading(false));
}