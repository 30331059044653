import { Col, Row, Form, Divider, message, Tag, Tabs } from "antd";
import Card from "../../component/Card";
import Input from "../../component/Input";
import Upload from "../../component/Upload";
import Button from "../../component/Button";
import { FileOutlined } from "@ant-design/icons";

import { ProfileWrapper, ContentWrapper } from "./styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  fetchProfile,
  updateProviderProfile,
  updateIntegrationProfile,
  uploadIntegrationDocument,
  fetchIntegrationDocuments,
  requestActivation,
} from "../../redux/reducers/auth";
import moment from "moment";
import DatePicker from "../../component/DatePicker";
import PasswordAPIRequestModal from "./containers/PasswordAPIRequestModal";
import TextArea from "../../component/TextArea";
import {
  UploadMainWrapper,
  PreviewWrapper,
} from "../../component/Upload/styles";
import AvatarImg from "../../component/Avatar";
import Loader from "../../component/Loader";
import Storage from "../../utils/firebase-storage";
const { TabPane } = Tabs;

const Profile = (props: any) => {
  const [basiform] = Form.useForm();
  const [otherForm] = Form.useForm();
  const [testWebhookForm] = Form.useForm();
  const [liveWebhookForm] = Form.useForm();
  const [showAPIKey, setShowAPIKey] = useState(false);
  const dispatch = useDispatch();
  const [
    isSendingAccountActivationRequest,
    setIsSendingAccountActivationRequest,
  ] = useState(false);
  const [isBasicInfoUpdateLoading, setIsBasicInfoUpdateLoading] =
    useState(false);
  const [isOtherInfoUpdateLoading, setIsOtherInfoUpdateLoading] =
    useState(false);
  const [isTestWebhookInfoUpdateLoading, setIsTestWebhookInfoUpdateLoading] =
    useState(false);
  const [isLiveWebhookInfoUpdateLoading, setIsLiveWebhookInfoUpdateLoading] =
    useState(false);

  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [uploadProgressObj, setUploadProgressObj] = useState({
    progress: false,
    index: 0,
  });
  const {
    currentUser,
    isProfileLoading,
    apiKeys,
    isAPIKeysLoading,
    integrationDocuments,
    isIntegrationDocumentsLoading,
  } = useSelector(authSelector);

  const getIntegrationDocumentsImage = (docType: string) => {
    const value: any = integrationDocuments.find((item: any) => {
      return item?.type === docType;
    });
    return value?.url || null;
  };

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  useEffect(() => {
    const { integration } = currentUser || {};
    if (integration) {
      dispatch(fetchIntegrationDocuments(integration));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    const {
      first_name,
      last_name,
      email,
      phone,
      address,
      dob,
      support_email,
      business_name,
      description,
      live_webhook_endpoint,
      test_webhook_endpoint,
    } = currentUser || {};
    const { street_line_one, state, city, country } = address || {};
    basiform.setFieldsValue({
      first_name,
      last_name,
      email,
      phone,
      dob: dob ? moment(dob, "DD/MM/YYYY") : null,
      street_line_one,
      state,
      city,
      country,
    });
    otherForm.setFieldsValue({
      business_name,
      support_email,
      description,
      live_webhook_endpoint,
      test_webhook_endpoint,
    });
    liveWebhookForm.setFieldsValue({
      live_webhook_endpoint,
    });
    testWebhookForm.setFieldsValue({
      test_webhook_endpoint,
    });
  }, [currentUser, basiform, otherForm, testWebhookForm, liveWebhookForm]);

  const handleSubmitOtherDetails = async (values: any) => {
    setIsOtherInfoUpdateLoading(true);
    const { integration } = currentUser || {};
    const { business_name, description, support_email } = values;
    const payload = { business_name, description, support_email };
    await dispatch(updateIntegrationProfile(integration, payload));
    await dispatch(
      updateProviderProfile({
        support_email,
      })
    );
    setIsOtherInfoUpdateLoading(false);
  };

  const handleTestSubmitWebhookEndpoints = async (values: any) => {
    const { integration } = currentUser || {};
    const { test_webhook_endpoint } = values;
    const payload = {
      test_webhook_endpoint: test_webhook_endpoint || "",
    };
    setIsTestWebhookInfoUpdateLoading(true);
    await dispatch(updateIntegrationProfile(integration, payload));
    setIsTestWebhookInfoUpdateLoading(false);
  };

  const handleLiveSubmitWebhookEndpoints = async (values: any) => {
    const { integration } = currentUser || {};
    const { live_webhook_endpoint } = values;
    const payload = {
      live_webhook_endpoint: live_webhook_endpoint || "",
    };

    setIsLiveWebhookInfoUpdateLoading(true);
    await dispatch(updateIntegrationProfile(integration, payload));
    setIsLiveWebhookInfoUpdateLoading(false);
  };

  const handleSubmitBasicDetails = async (values: any) => {
    setIsBasicInfoUpdateLoading(true);
    const {
      first_name,
      last_name,
      phone,
      street_line_one,
      city,
      dob,
      state,
      country,
    } = values;
    const payload: any = {
      first_name,
      last_name,
      phone,
      address: {
        street_line_one,
        city,
        state,
        country,
      },
    };
    if (dob) {
      payload.dob = moment(dob).format("DD/MM/YYYY");
    }
    await dispatch(updateProviderProfile(payload));
    setIsBasicInfoUpdateLoading(false);
  };

  const handleAccountActivation = async () => {
    setIsSendingAccountActivationRequest(true);
    await dispatch(requestActivation(integration));
    setIsSendingAccountActivationRequest(false);
  };

  const testSecretAPIKey = apiKeys?.find(
    (item) => item.type === "secret" && item.mode === "sandbox"
  )?.key;
  const liveSecretAPIKey = apiKeys?.find(
    (item) => item.type === "secret" && item.mode === "live"
  )?.key;
  const testPublicAPIKey = apiKeys?.find(
    (item) => item.type === "public" && item.mode === "sandbox"
  )?.key;
  const livePublicAPIKey = apiKeys?.find(
    (item) => item.type === "public" && item.mode === "live"
  )?.key;

  useEffect(() => {
    if (apiKeys.length > 0) {
      setShowAPIKey(true);
    } else {
      setShowAPIKey(false);
    }
  }, [apiKeys]);

  const handleFileUpload = (type: string, file: any, index: number) => {
    const uploadTask = Storage.ref(
      `/integration-docs/${integration}/files/${file.name}`
    ).put(file);

    uploadTask.on(
      "state_changed",
      (snapshot: any) => {
        const progress =
          Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        if (progress !== 100) {
          setUploadProgressObj({
            progress: true,
            index,
          });
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(async (url) => {
          const payload = {
            url,
            type,
            number: 8474646,
          };

          const { integration } = currentUser || {};
          await dispatch(uploadIntegrationDocument(integration, payload));
          setUploadProgressObj({
            progress: false,
            index,
          });
        });
      }
    );
  };

  const uploadProps = (type: string, index: number) => ({
    showUploadList: false,
    async customRequest(options: any) {
      const { file } = options;
      await handleFileUpload(type, file, index);
    },
    onChange(info: any) {
      const status = info.file.status;
      if (status === "done" || status === "success") {
        message.success(`File uploaded successfully`);
        setUploadProgressObj({
          progress: false,
          index,
        });
        if (integration) {
          dispatch(fetchIntegrationDocuments(integration));
        }
      } else if (status === "error") {
        message.error(`File upload failed.`);
        setUploadProgressObj({
          progress: false,
          index,
        });
      }
    },
  });

  const Preview = ({ type }: any) => {
    return (
      getIntegrationDocumentsImage(type) && (
        <a target="_blank" rel="noreferrer" href={`${getIntegrationDocumentsImage(type)}`}>
          <PreviewWrapper>
            <FileOutlined style={{ marginRight: "10px" }} />
            {type}
          </PreviewWrapper>
        </a>
      )
    );
  };

  function callback(key: any) {
    console.log(key);
  }

  const { first_name, last_name, integration } = currentUser || {};
  const allDocuments = [
    "CAC Certificate And Memorandum Of Association",
    "Privacy Policy",
    "Terms Of Service",
    "Signed Data Agreement",
    "National ID",
  ];

  const dashboardMode =
    window.localStorage.getItem("pneumaDashboardMode") || "integration";
  const { index: progressIndex, progress } = uploadProgressObj;

  const uploadBtnLabel = (type: string, index: number) => {
    if (getIntegrationDocumentsImage(type)) {
      return progress && progressIndex === index ? "Replacing..." : "Replace";
    } else {
      return progress && progressIndex === index ? "Uploading..." : "Upload";
    }
  };

  return (
    <ProfileWrapper>
      <Row>
        <Col xs={24} md={24}>
          <ContentWrapper>
            <h2>Manage Account</h2>
            <Tabs defaultActiveKey="1" tabPosition="top" onChange={callback}>
              <TabPane tab="Profile" key="1">
                {isProfileLoading ? (
                  <Loader />
                ) : (
                  <Row justify="center">
                    <Col xs={24} md={16}>
                      <Form
                        name="basicForm"
                        layout="vertical"
                        form={basiform}
                        onFinish={handleSubmitBasicDetails}
                      >
                        <Card
                          hasBoxShadow={false}
                          marginBottom={15}
                          padding={20}
                        >
                          <Row gutter={24} justify="start" align="middle">
                            <Col
                              xs={24}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <AvatarImg
                                img=""
                                style={{
                                  marginRight: 5,
                                  marginBottom: 10,
                                  width: "96px",
                                  height: "96px",
                                  fontSize: "26px",
                                }}
                                fallbackText={`${first_name} ${last_name}`}
                              />
                            </Col>
                            <Col
                              xs={24}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div style={{ textAlign: "center" }}>
                                <Upload
                                  type="secondary"
                                  style={{ marginBottom: 5 }}
                                >
                                  Add a profile photo
                                </Upload>
                                <small>
                                  Allowed JPG, GIF or PNG. Max size of 2MB
                                </small>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                        <h3 style={{ marginTop: "25px", color: "#0f9af0" }}>
                          Personal Info
                        </h3>
                        <Card
                          hasBoxShadow={false}
                          marginBottom={15}
                          padding={0}
                        >
                          <Row gutter={24}>
                            <Col md={24} lg={12}>
                              <Input
                                formItem={{
                                  label: "First Name",
                                  name: "first_name",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please input your first name",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                              />
                            </Col>
                            <Col span={12}>
                              <Input
                                formItem={{
                                  label: "Last Name",
                                  name: "last_name",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please input your last name",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                              />
                            </Col>
                          </Row>

                          <Row gutter={24}>
                            <Col span={24}>
                              <Input
                                formItem={{
                                  label: "Email address",
                                  name: "email",
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please input your Email address",
                                    },
                                    {
                                      type: "email",
                                      message: "Please input a valid email",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="email"
                                label="Email address"
                                disabled
                              />
                            </Col>
                          </Row>

                          <Row gutter={24}>
                            <Col span={12}>
                              <Input
                                formItem={{
                                  label: "Phone number",
                                  name: "phone",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please enter your phone number",
                                    },
                                    {
                                      min: 11,
                                      message:
                                        "Phone number cannot be less than 11 characters",
                                    },
                                    {
                                      max: 11,
                                      message:
                                        "Phone number cannot be greater than 11 characters",
                                    },
                                    {
                                      pattern: /^\d+$/,
                                      message:
                                        "Phone number can only contain digits",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                                label="Phone number"
                              />
                            </Col>
                            <Col span={12}>
                              <DatePicker
                                formItem={{
                                  label: "Date of Birth",
                                  name: "dob",
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please input your Date of Birth",
                                    },
                                  ],
                                }}
                                mode="normal"
                                format="DD/MM/yyyy"
                                type="text"
                              />
                            </Col>
                          </Row>

                          <Row gutter={24}>
                            <Col span={24}>
                              <Input
                                formItem={{
                                  label: "Street Address",
                                  name: "street_line_one",
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please input your Street Address",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                                label="Street Address"
                              />
                            </Col>
                          </Row>

                          <Row gutter={24}>
                            <Col xs={8} md={8}>
                              <Input
                                formItem={{
                                  label: "City",
                                  name: "city",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please input your City",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                              />
                            </Col>
                            <Col xs={8} md={8}>
                              <Input
                                formItem={{
                                  label: "State",
                                  name: "state",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please input your State",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                              />
                            </Col>
                            <Col xs={8} md={8}>
                              <Input
                                formItem={{
                                  label: "Country",
                                  name: "country",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please input your Country",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                              />
                            </Col>
                          </Row>

                          <Row gutter={24} justify="end">
                            <Button
                              htmlType="submit"
                              className="update-profile-btn"
                              type="primary"
                            >
                              {isBasicInfoUpdateLoading
                                ? "Updating..."
                                : "Update Personal Info"}
                            </Button>
                          </Row>
                        </Card>
                      </Form>

                      <Form
                        name="otherForm"
                        layout="vertical"
                        form={otherForm}
                        onFinish={handleSubmitOtherDetails}
                      >
                        <h3 style={{ marginTop: "25px", color: "#0f9af0" }}>
                          Business Details
                        </h3>
                        <Card
                          hasBoxShadow={false}
                          marginBottom={15}
                          padding={0}
                        >
                          <Row gutter={24}>
                            <Col xs={24} md={12}>
                              <Input
                                formItem={{
                                  label: "Business Name",
                                  name: "business_name",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please input business name",
                                    },
                                  ],
                                }}
                                mode="normal"
                                type="text"
                              />
                            </Col>
                            <Col xs={24} md={12}>
                              <Input
                                formItem={{
                                  label: "Support Email",
                                  name: "support_email",
                                }}
                                placeholder="Enter support email"
                                mode="normal"
                                // marginBottom="0"
                                type="text"
                              />
                            </Col>
                            <Col span={24}>
                              <TextArea
                                formItem={{
                                  label: "Description",
                                  name: "description",
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please input business description",
                                    },
                                  ],
                                }}
                                type="text"
                              />
                            </Col>
                          </Row>
                          <Row gutter={24} justify="end">
                            <Button
                              htmlType="submit"
                              className="update-profile-btn"
                              type="primary"
                            >
                              {isOtherInfoUpdateLoading
                                ? "Updating..."
                                : "Update Business Info"}
                            </Button>
                          </Row>
                        </Card>
                      </Form>
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tab="Compliance documents" key="2">
                <Row justify="center">
                  <Col xs={24} md={16}>
                    <Form layout="vertical">
                      <Row gutter={16} style={{ marginBottom: "20px" }}>
                        <Col xs={24} md={24}>
                          <p style={{ marginBottom: 0 }}>
                            Please upload ALL your business documents to
                            activate your account
                          </p>
                        </Col>
                      </Row>
                      <Card marginBottom={15} padding={30}>
                        {isIntegrationDocumentsLoading ? (
                          <Loader />
                        ) : (
                          <Row gutter={20}>
                            {allDocuments.map((type, index) => {
                              return (
                                <Col xs={24} md={24} key={index}>
                                  <UploadMainWrapper>
                                    <div className="flex">
                                      <span>{type}</span>
                                      <Upload
                                        {...uploadProps(type, index)}
                                        type="secondary"
                                      >
                                        {uploadBtnLabel(type, index)}
                                      </Upload>
                                    </div>
                                    <Preview type={type} />
                                  </UploadMainWrapper>
                                </Col>
                              );
                            })}
                            <Col xs={24} md={24}>
                              <Button
                                type="primary"
                                onClick={handleAccountActivation}
                                disabled={isSendingAccountActivationRequest}
                              >
                                {isSendingAccountActivationRequest
                                  ? "Requesting..."
                                  : "Request Account Activation"}
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Card>
                    </Form>
                  </Col>
                </Row>
              </TabPane>
              {dashboardMode === "integration" && (
                <TabPane tab="API keys & webhooks" key="3">
                  <Row justify="center">
                    <Col xs={24} md={16}>
                      <Card marginBottom={15} padding={30}>
                        <Row>
                          <Col xs={24} md={12}>
                            <h3 style={{ marginBottom: 10 }}>
                              Developer's API configuration
                            </h3>
                          </Col>
                          <Col xs={24} md={12} style={{ textAlign: "right" }}>
                            <Tag
                              color="#0f9af0"
                              style={{
                                fontSize: "13px",
                                marginBottom: 10,
                                padding: "4px 10px",
                              }}
                            >
                              Integration ID: {integration}
                            </Tag>
                          </Col>
                        </Row>
                        <Divider style={{ margin: "10px 0" }} />
                        <p style={{ marginBottom: "5px" }}>
                          Check how to use it in our{" "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://pneumacare.readme.io/reference/getting-started-with-your-api"
                          >
                            API documentation
                          </a>
                          , and join our{" "}
                          <a href="https://discord.gg/aEHk6hbC">
                            developer community
                          </a>
                          .
                        </p>
                        <Divider />
                        <div>
                          <Row gutter={10}>
                            <Col span={24}><h4>TEST KEYS</h4></Col>
                            <Col xs={24} lg={12} className="api-section">
                              <p style={{ marginBottom: 5 }}>Secret key</p>
                              <Input
                                type="text"
                                value={
                                  showAPIKey
                                    ? testSecretAPIKey
                                    : "*************************"
                                }
                                marginBottom={10}
                                iconRender={() => null}
                                mode="normal"
                                placeholder="Secret key"
                              />
                            </Col>
                            <Col
                              xs={24} lg={12}
                              className="api-section"
                            >
                              <p style={{ marginBottom: 5 }}>Public key</p>
                              <Input
                                type="text"
                                value={
                                  showAPIKey
                                    ? testPublicAPIKey
                                    : "*************************"
                                }
                                marginBottom={10}
                                iconRender={() => null}
                                mode="normal"
                                placeholder="Public key"
                              />
                            </Col>
                            <Divider />
                            <Col span={24}><h4>LIVE KEYS</h4></Col>
                            <Col xs={24} lg={12} className="api-section">
                              <p style={{ marginBottom: 5 }}>Secret key</p>
                              <Input
                                type="text"
                                value={
                                  showAPIKey
                                    ? liveSecretAPIKey
                                    : "*************************"
                                }
                                marginBottom={10}
                                iconRender={() => null}
                                mode="normal"
                                placeholder="Secret key"
                              />
                            </Col>
                            <Col
                              xs={24} lg={12}
                              className="api-section"
                            >
                              <p style={{ marginBottom: 5 }}>Public key</p>
                              <Input
                                type="text"
                                value={
                                  showAPIKey
                                    ? livePublicAPIKey
                                    : "*************************"
                                }
                                marginBottom={10}
                                iconRender={() => null}
                                mode="normal"
                                placeholder="Public key"
                              />
                            </Col>
                          </Row>
                          
                          <div className="align-right">
                            {showAPIKey ? (
                              <span
                                onClick={() => setShowAPIKey(false)}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Hide API keys
                              </span>
                            ) : (
                              <span
                                onClick={() => setIsPasswordModalOpen(true)}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Show API keys
                              </span>
                            )}
                          </div>
                        </div>
                        <Form
                          name="otherForm"
                          layout="vertical"
                          form={testWebhookForm}
                          initialValues={currentUser}
                          onFinish={handleTestSubmitWebhookEndpoints}
                        >
                          <h3 style={{ marginTop: "25px" }}>
                            Set webhook endpoints
                          </h3>
                          <div>
                            <Row gutter={24}>
                              <Col span={24}>
                                <Input
                                  formItem={{
                                    label: "Test webhook endpoint",
                                    name: "test_webhook_endpoint",
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please input a valid link",
                                      },
                                    ],
                                  }}
                                  marginBottom="0"
                                  mode="normal"
                                  type="text"
                                />
                              </Col>
                            </Row>
                            <Row gutter={24} justify="end">
                              <Col span={24} style={{ textAlign: "right" }}>
                                <Button
                                  htmlType="submit"
                                  className="update-profile-btn"
                                  type="secondary"
                                >
                                  {isTestWebhookInfoUpdateLoading
                                    ? "Updating..."
                                    : "Update test webhook"}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                        <Form
                          name="otherForm"
                          layout="vertical"
                          form={liveWebhookForm}
                          onFinish={handleLiveSubmitWebhookEndpoints}
                        >
                          <div style={{ marginTop: "25px" }}>
                            <Row gutter={24}>
                              <Col span={24}>
                                <Input
                                  formItem={{
                                    label: "Live webhook endpoint",
                                    name: "live_webhook_endpoint",
                                    rules: [
                                      {
                                        required: true,
                                        message: "Please input a valid link",
                                      },
                                    ],
                                  }}
                                  marginBottom="0"
                                  mode="normal"
                                  type="text"
                                />
                              </Col>
                            </Row>
                            <Row gutter={24} justify="end">
                              <Col span={24} style={{ textAlign: "right" }}>
                                <Button
                                  htmlType="submit"
                                  className="update-profile-btn"
                                  type="secondary"
                                >
                                  {isLiveWebhookInfoUpdateLoading
                                    ? "Updating..."
                                    : "Update live webhook"}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                        <PasswordAPIRequestModal
                          visible={isPasswordModalOpen}
                          handleCancel={() => setIsPasswordModalOpen(false)}
                        />
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              )}
            </Tabs>
            {/* {isProfileLoading ? <Loader background="transparent" /> : <></>} */}
          </ContentWrapper>
        </Col>
      </Row>
    </ProfileWrapper>
  );
};

export default Profile;
