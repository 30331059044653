import firebase from "firebase";

const firebaseConfigDev = {
  apiKey: "AIzaSyAElNSxA6Lr4IUQiimetGN4BGigt3EMFPg",
  authDomain: "integration-dev-pneuma.firebaseapp.com",
  projectId: "integration-dev-pneuma",
  storageBucket: "integration-dev-pneuma.appspot.com",
  messagingSenderId: "901983525447",
  appId: "1:901983525447:web:756639b39f6444c50d2f62"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyCQRJIrYTwK9fw4lrG2QUpTECnj_9VXPu4",
  authDomain: "integration-dashboard-prod.firebaseapp.com",
  projectId: "integration-dashboard-prod",
  storageBucket: "integration-dashboard-prod.appspot.com",
  messagingSenderId: "152236647434",
  appId: "1:152236647434:web:a0b08131f34e565d4bebf3",
  measurementId: "G-C71XEBGPCR"
};

firebase.initializeApp(
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? firebaseConfigDev
    : firebaseConfigProd
);

// Get a reference to the storage service, which is used to create references in your storage bucket
const Storage = firebase.storage();

export default Storage;
