import styled from 'styled-components';

const TransactionsTableWrapper = styled.div`

`;

const TransactionsTableHeader = styled.div`
    height: auto;
    padding: 10px 20px;
    align-items: center;
    h3 {
      margin-bottom: 0px;
    }
`;

const TransactionsTableBody = styled.div`
  border-radius: 8px;
  .ant-card {
    box-shadow: none !important;
    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }
  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }
  .ant-table-wrapper {
    border-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table {
    border-radius: 8px;
    .odd-row td.ant-table-cell {
      background: #0f9af01a;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }
    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }
    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }
    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 16px;
      border-bottom: 0.5px solid #1890ff;
    }
    tr {
      td:first-child,
      th:first-child {
        padding: 10px 20px;
      }
    }
    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
      color: #025bbb;
    }
    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;
      .flex {
        display: flex;
        img {
          margin-right: 10px;
          border: 0.8px solid #0f9af0;
          border-radius: 50%;
          height: 100%;
          padding: 2px;
        }
      }
      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }
      .action {
        img {
          margin-left: 10px;
        }
      }
      p {
        margin-bottom: 5px;
      }
      span {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0f9af0;
        opacity: 0.75;
      }
    }
  }
`;

export { TransactionsTableWrapper, TransactionsTableHeader, TransactionsTableBody }