import styled from 'styled-components';


const TransactionsWrapper = styled.div`
    width: 100%;
    min-height: calc(100vh - 57px);
    h3 {
        font-family: "DM Sans";
        font-weight: 400;
        font-size: 18px;
        /* margin-bottom: 0px; */
        text-align: left;
        color: #4e667e;
    }
`;

const ContentWrapper = styled.div`
    padding: 25px;
    background: #f3f8fb;
    min-height: calc(100vh - 60px);
    padding-bottom: 100px;
    .update-profile-btn {
        margin: 50px 0px 0px 50px;
    }
    .wallet-card-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`;

const StatsCardWrapper = styled.div`
    cursor: pointer;
    .ant-card {
        border-radius: 0px;
        box-shadow: 0px 0px 3px #0f9af01A;
    }
    p {
        font: normal normal medium 13px/16px DM Sans;
        letter-spacing: 0px;
        color: #002E46
    }
    h3 {
        text-align: left;
        font: normal normal medium 13px/14px DM Sans;
        line-height: 18px;
        letter-spacing: 0px;
        color: #002E46;
        word-break: break-all;
        white-space: pre-line;
        opacity: 1;
        margin-bottom: 24px;
    }
    h2 {
        text-align: left;
        font: normal normal normal 28px/34px DM Sans;
        letter-spacing: 0px;
        color: #002E46;
        opacity: 0.5;
        margin-bottom: 0px;
        margin-top: 30px;
    }
    .btn-topUp {
        padding: 7px 10px;
        @media(max-width: 390px) {
            margin-top: 30px;
        }
    }
`;

const BlankContentWrapper = styled.div`
    background: #fff;
    height: 100%;
    box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
`;

const AddTextWrapper = styled.p`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    margin-top: 5px;
    cursor: pointer;
    color: #0f9af0;
    img {
        margin-right: 7px;
    }

`;

export { TransactionsWrapper, ContentWrapper, BlankContentWrapper, AddTextWrapper, StatsCardWrapper }