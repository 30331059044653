import { useState } from "react";
import { Table, Tooltip } from "antd";
import { capitalize } from "lodash";
import { useHistory } from "react-router-dom";

import Button from "component/Button";
import { TableWrapper, TableBody } from "../ProvidersTable/styles";

// svg
import { ReactComponent as EmptyProvidersIcon } from "assets/icons/empty-providers-icon.svg";
import { ReactComponent as AddProviderIcon } from "assets/icons/add-provider-icon.svg";
import { ReactComponent as RemoveProviderIcon } from "assets/icons/remove-provider-icon.svg";

import { SingleLocalProvider, SingleProvider } from "utils/apiTypes";
import AddProviderModal from "component/AddProviderModal";
import RemoveProviderModal from "component/RemoveProviderModal";

interface Props {
  providers: SingleProvider[];
  myProviders: SingleLocalProvider[];
  isLoading: boolean;
}

function AllProviders({ providers, isLoading, myProviders }: Props) {
  const history = useHistory();

  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({
    name: "",
    id: ""
  });

  const columns = [
    {
      title: "Provider Name",
      dataIndex: "business_name",
      width: 386
    },
    {
      title: "Category",
      dataIndex: "type",
      render: (_: string, record: SingleProvider) => {
        const dirtyType = record.type;

        // remove underscores
        let cleanType = dirtyType.replace("_", " ");

        // seperate multiple words
        const cleanWord1 = cleanType.split(" ")[0];
        const cleanWord2 = cleanType.split(" ")[1];

        return `${capitalize(cleanWord1)} ${capitalize(cleanWord2)}`;
      }
    },
    {
      title: "State",
      dataIndex: ["address", "state"]
    },
    {
      title: "City/Town",
      dataIndex: ["address", "city"],
      render: (_: string, record: SingleProvider) =>
        capitalize(record.address.city)
    },
    {
      title: "",
      render: (_: string, record: SingleProvider) => {
        const isMyProvider = myProviders.find(
          (provider) => provider.service_provider.id === record.id
        );

        return (
          <div className='action-btns'>
            <Tooltip title={isMyProvider ? "Remove Provider" : "Add Provider"}>
              <Button
                onClick={() => {
                  setSelectedProvider({
                    name: record.business_name,
                    id: record.id
                  });

                  if (isMyProvider) {
                    // handle remove provider here
                    setShowRemoveModal(true);
                  } else {
                    // handle add provider
                    setShowAddModal(true);
                  }
                }}
              >
                {isMyProvider ? <RemoveProviderIcon /> : <AddProviderIcon />}
              </Button>
            </Tooltip>
            <Button onClick={() => history.push(`/providers/${record.id}`)}>
              View Provider
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <TableWrapper>
        <TableBody>
          <Table
            dataSource={providers}
            columns={columns}
            loading={isLoading}
            scroll={!isLoading && providers?.length! > 0 ? { x: 700 } : {}}
            rowClassName={(_, index) =>
              index % 2 === 0 ? "even-row" : "odd-row"
            }
            pagination={{
              pageSize: 20
            }}
            locale={{
              emptyText: (
                <div className='empty-wrapper'>
                  <div className='empty-content'>
                    <EmptyProvidersIcon />
                    <h4>No Provider Added</h4>
                    <p>All providers will show here</p>
                  </div>
                </div>
              )
            }}
          />
        </TableBody>
      </TableWrapper>

      {selectedProvider.id ? (
        <>
          <AddProviderModal
            handleCancel={() => setShowAddModal(false)}
            isModalVisible={showAddModal}
            selectedProvider={selectedProvider}
          />

          <RemoveProviderModal
            handleCancel={() => setShowRemoveModal(false)}
            isModalVisible={showRemoveModal}
            selectedProvider={selectedProvider}
          />
        </>
      ) : null}
    </>
  );
}

export default AllProviders;
