import { Upload as AntdUpload } from "antd"
import Button from "../Button";
import { UploadOutlined } from '@ant-design/icons';

const Upload = (props: any) => {
    const { type, children } = props;
    return (
        <AntdUpload {...props}>
            <Button type={type} icon={<UploadOutlined />}>{children}</Button>
        </AntdUpload>
    )
}

export default Upload;