import { Col, Row, Table } from "antd";
import Card from "../../../../component/Card";
import { EncounterTableWrapper, EncounterTableBody } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import {
  consultSelector,
  fetchAllConsults,
} from "../../../../redux/reducers/consults";
import { useEffect, useState } from "react";
import moment from "moment";
import AvatarImg from "../../../../component/Avatar";
import ShowMore from "react-show-more";
import Search from "../../../../component/Search";

interface EncounterTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
}

const EncounterTable = ({
  count,
  hasPagination = true,
  marginTop = 20,
}: EncounterTableProps) => {
  const dispatch = useDispatch();
  const { consults, isConsultsLoading } = useSelector(consultSelector);
  const [keyword, setKeyword] = useState("");
  const [encountersState, setEncountersState] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchAllConsults());
  }, [dispatch]);

  useEffect(() => {
    setEncountersState(consults);
  }, [consults]);

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _consults = [...consults];
    setEncountersState(
      _consults.filter((item) => {
        const { patient } = item;
        const fullName = `${patient.first_name} ${patient.last_name}`;
        return fullName.toLowerCase().includes(keyword.toLowerCase());
      })
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "first_name",
      width: "200px",
      sorter: (a: any, b: any) =>
        a.user.first_name.toLowerCase() < b.user.first_name.toLowerCase()
          ? -1
          : 1,
      render: (_: string, record: any) => {
        const { first_name, last_name } = record?.user || {};
        return (
          <div className="flex" style={{ alignItems: "center" }}>
            <AvatarImg
              img=""
              style={{ marginRight: 10 }}
              fallbackText={`${first_name} ${last_name}`}
            />
            <div>
              <p style={{ marginBottom: 0 }}>
                {first_name} {last_name}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Reason(s) for encounter",
      dataIndex: "reasons",
      key: "reasons",
      width: 250,
      render: (_: any, record: any) => {
        return record?.symptoms?.length > 0 ? (
          <p className="text-ellipsis" style={{ lineHeight: "20px" }}>
            <ShowMore
              lines={2}
              more="Show more"
              less="Show less"
              anchorClass=""
            >
              {record.symptoms.map((item: any) => item.symptom).join(", ")}
            </ShowMore>
          </p>
        ) : (
          <p>—</p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as "center",
      render: (status: string) => {
        return <p style={{ textTransform: "capitalize" }}>{status}</p>;
      },
    },
    {
      title: "Nature of encounter",
      dataIndex: "nature",
      key: "nature",
      align: "center" as "center",
      render: (nature: string = "Virtual") => {
        return <p>{nature}</p>;
      },
    },
    {
      title: "Schedule",
      dataIndex: "schedule",
      key: "schedule",
      render: (created_at: Date, record: any) => {
        return (
          <>
            <p>{moment(record.created_at).format("LL")}</p>
            <span>{moment(record.created_at).format("hh:mm a")}</span>
          </>
        );
      },
    },
  ];

  const countText =
    encountersState.length === consults.length
      ? `${encountersState.length}`
      : `${encountersState.length}/${consults.length}`;

  const dataSource = count ? encountersState.slice(0, count) : encountersState;
  return (
    <>
      {!count && (
        <Row justify="space-between" align="middle">
          <Col xs={24} md={12}>
            <h3>
              {count ? "Recent Encounters" : "Encounters"}{" "}
              <small>({countText})</small>
            </h3>
          </Col>
          <Col xs={24} md={12}>
            <Search
              placeholder="Search by patient's name"
              onChange={handleSearch}
            />
          </Col>
        </Row>
      )}
      <EncounterTableWrapper>
        <Card marginBottom={15} padding={0} style={{ marginTop }}>
          <EncounterTableBody hasPagination={hasPagination}>
            <Table
              dataSource={dataSource}
              columns={columns}
              scroll={!isConsultsLoading && dataSource.length > 0 ? { x: 700 } : {}}
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isConsultsLoading}
              pagination={
                hasPagination
                  ? { position: ["bottomRight"], showLessItems: true }
                  : false
              }
            />
          </EncounterTableBody>
        </Card>
      </EncounterTableWrapper>
    </>
  );
};

export default EncounterTable;
