import { Col, Row, Table } from "antd";
import Card from "../../../../component/Card";
import {
  TransactionsTableWrapper,
  TransactionsTableHeader,
  TransactionsTableBody,
} from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  fetchAllTransactions,
  transactionSelector,
} from "../../../../redux/reducers/transactions";
import Search from "../../../../component/Search";

const TransactionsTable = (props: any) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [transactionsState, setTransactionsState] = useState<any[]>([]);
  const { transactions, isTransactionsLoading } =
    useSelector(transactionSelector);

  useEffect(() => {
    dispatch(fetchAllTransactions());
  }, [dispatch]);

  useEffect(() => {
    setTransactionsState(transactions);
  }, [transactions]);

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _users = [...transactions];
    setTransactionsState(
      _users.filter((item) => {
        const { source } = item;
        return source.toLowerCase().includes(keyword.toLowerCase());
      })
    );
  };

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
      render: (id: string) => {
        return <p>#{id}</p>;
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => {
        return <p>{description}</p>;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "100px",
      render: (amount: number) => {
        return <p>₦ {(amount ? amount / 100 : 0).toLocaleString()}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as "center",
      render: (status: string = "Completed") => {
        return <p>{status}</p>;
      },
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center" as "center",
      render: (created_at: Date, record: any) => {
        return <p>{moment(record.created_at).format("LL")}</p>;
      },
    },
  ];

  const count =
    transactionsState.length === transactions.length
      ? `${transactionsState.length}`
      : `${transactionsState.length}/${transactions.length}`;

  return (
    <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
      <TransactionsTableWrapper>
        <TransactionsTableHeader>
          <Row justify="space-between" align="middle">
            <Col xs={24} md={12}>
              <h3>
                All Transactions <small>({count})</small>
              </h3>
            </Col>
            <Col xs={24} md={12}>
              <Search
                placeholder="Search by request ID"
                onChange={handleSearch}
              />
            </Col>
          </Row>
        </TransactionsTableHeader>
        <TransactionsTableBody>
          <Table
            dataSource={transactionsState}
            columns={columns}
            scroll={!isTransactionsLoading && transactionsState.length > 0 ? { x: 700 } : {}}
            rowClassName={(_, index) =>
              index % 2 === 0 ? "even-row" : "odd-row"
            }
            loading={isTransactionsLoading}
            pagination={{ position: ["bottomRight"], showLessItems: true }}
          />
        </TransactionsTableBody>
      </TransactionsTableWrapper>
    </Card>
  );
};

export default TransactionsTable;
