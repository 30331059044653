const getAllAPIKeys = (apiKeys: any) => {
  const testPublicAPIKey = apiKeys?.find(
    (item: any) =>
      (item.mode === "sandb" || item.mode === "sandbox" || item.mode === "test") && item.type === "public"
  );
  const livePublicAPIKey = apiKeys?.find((item: any) => item.mode === "live" && item.type === "public");

  const testSecretAPIKey = apiKeys?.find(
    (item: any) =>
      (item.mode === "sandb" || item.mode === "sandbox" || item.mode === "test") && item.type === "secret"
  );
  const liveSecretAPIKey = apiKeys?.find((item: any) => item.mode === "live" && item.type === "secret");

  const { key: liveSecretKey } = liveSecretAPIKey || {};
  const { key: testSecretKey } = testSecretAPIKey || {};
  const { key: livePublicKey } = livePublicAPIKey || {};
  const { key: testPublicKey } = testPublicAPIKey || {};

  return { liveSecretKey, testSecretKey, livePublicKey, testPublicKey };

  // return {testKey, liveKey};
};

export default getAllAPIKeys;
