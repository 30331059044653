import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  .container {
    width: 100%;
    min-height: 100%;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 15px 20px 35px;

    @media (min-width: 768px) {
      padding: 25px 35px;
    }

    @media (min-width: 1024px) {
      padding: 35px 45px 40px;
    }

    .back-btn {
      display: inline-flex;
      align-items: center !important;
      justify-content: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid #94a3b8;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      cursor: pointer;
      padding: 8px;
      color: #344054;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      &:hover,
      &:focus {
        background-color: #e2e8f0;
      }
    }

    .ant-tabs-nav {
      margin-top: 40px;

      .ant-tabs-tab-btn {
        font-family: "DM Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        user-select: none;
        color: #0faff0;
      }
      .ant-tabs-ink-bar {
        background: #0faff0;
      }
    }
  }
`;

export const Header = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0;
  }

  .provider-details {
    .name {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;

      h2 {
        color: #344054;
        font-family: "DM Sans", sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 27.962px;
        margin: 0;

        @media (min-width: 1024px) {
          font-size: 32px;
          line-height: 47.962px;
        }
      }
    }

    .description {
      max-width: 670px;
      color: #344054;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .details {
      width: 100%;
      margin-top: 26px;
      display: flex;
      gap: 14px;
      flex-wrap: wrap;

      @media (min-width: 1024px) {
        gap: 24px;
      }

      .single-detail {
        min-width: 170px;

        @media (min-width: 768px) {
          max-width: 350px;
        }

        h4 {
          color: #334155;
          font-family: "DM Sans", sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          margin: 0 0 3px;

          @media (min-width: 1024px) {
            font-size: 16px;
            line-height: 18px;
          }
        }

        p {
          color: #64748b;
          font-family: "DM Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          margin: 0;
        }

        a {
          color: #0faff0;
          font-family: "DM Sans", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;

          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .add-btn,
  .remove-btn {
    button {
      border-radius: 4px;
      color: #fff;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;

      @media (min-width: 1024px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .add-btn {
    button {
      background: #0faff0;
    }
  }

  .remove-btn {
    button {
      background: #eb5757;
    }
  }
`;

export const PlansGridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2em 1em;

  .single-plan {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    border-radius: 4px;
    border: 0.77px solid #d0d5dd;
    padding: 16px;

    > div {
      h5 {
        margin: 0 0 8px;
        color: #344054;
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
      }

      p {
        margin: 0;
        color: #344054;
        font-family: "DM Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 120%;
      }
    }

    p.price {
      color: #334155;
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 120%;
      margin: 0;
    }
  }
`;

export const OtherWrapper = styled.div`
  width: 100%;
  display: grid;
  place-content: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
  }

  h5 {
    color: #344054;
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    max-width: 400px;
    text-align: center;
    margin-top: 12px;
  }
`;
