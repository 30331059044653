import { Col, Empty, Pagination, Row } from "antd";
import Card from "../../../../component/Card";
import { FormTableWrapper, FormTableBody, FormItemWrapper } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { fetchAllForms, formSelector } from "../../../../redux/reducers/forms";
import Button from "../../../../component/Button";
import FormDrawer from "../FormDrawer";
import Loader from "../../../../component/Loader";
import Search from "../../../../component/Search";

const FormTable = (props: any) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { forms, isFormsLoading } = useSelector(formSelector);
  const [formState, setFormState] = useState<any[]>([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isFormDrawerOpen, setIsFormDrawerOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAllForms());
  }, [dispatch]);

  useEffect(() => {
    setFormState(forms);
  }, [forms]);

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _forms = [...forms];
    setFormState(
      _forms.filter((item) =>
        item.name.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  };

  const handleOpenQuestions = (item: any) => {
    setSelectedForm(item);
    setIsFormDrawerOpen(true);
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  }

  const count =
    formState.length === forms.length
      ? `${formState.length}`
      : `${formState.length}/${forms.length}`;

  const startIndex = (currentPage - 1) * 10;
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col xs={24} md={12}>
          <h3>
            Consult Forms <small>({count})</small>
          </h3>
        </Col>
        <Col xs={24} md={12}>
          <Search placeholder="Search by form's name" onChange={handleSearch} />
        </Col>
      </Row>
      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <FormTableWrapper>
          <FormTableBody>
            {isFormsLoading ? (
              <Loader />
            ) : formState.length === 0 ? (
              <Empty description="No forms added yet" />
            ) : (
              formState.slice(startIndex, startIndex + 10).map((item, index) => {
                const {
                  id,
                  name,
                  description,
                  created_at,
                  number_of_questions,
                } = item;
                return (
                  <FormItemWrapper index={index}>
                    <Row gutter={20}>
                      <Col xs={24} md={16}>
                        <h3>
                          {startIndex + index + 1}. {name}
                        </h3>
                        <i>Created on {moment(created_at).format("LL")}</i>
                        <div>
                          <span className="tag">ID: {id}</span>
                        </div>
                        <p>{description}</p>
                      </Col>
                      <Col xs={24} md={8} className="align-right">
                        <Button
                          className="btn-questions"
                          type="secondary"
                          onClick={() => handleOpenQuestions(item)}
                        >
                          View {number_of_questions || 0} Question(s)
                        </Button>
                      </Col>
                    </Row>
                  </FormItemWrapper>
                );
              })
            )}
            {
              !isFormsLoading && formState.length > 0 &&
              <div style={{ padding: "20px 20px" }}>
                <Pagination defaultCurrent={1} total={formState.length} onChange={handlePaginationChange} />
              </div>
            }
          </FormTableBody>
        </FormTableWrapper>
        <FormDrawer
          isVisible={isFormDrawerOpen}
          selectedForm={selectedForm}
          onClose={() => setIsFormDrawerOpen(false)}
        />
      </Card>
    </>
  );
};

export default FormTable;
