import { Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as RemoveProviderIcon } from "assets/icons/remove-providers-icon.svg";

import {
  removeProviderFromNetwork,
  providersSelector
} from "redux/reducers/providers";

interface Props {
  isModalVisible: boolean;
  handleCancel: () => void;
  selectedProvider: { name: string; id: string };
}

function RemoveProviderModal({
  isModalVisible,
  handleCancel,
  selectedProvider
}: Props) {
  const dispatch = useDispatch();
  const { removeProviderLoading } = useSelector(providersSelector);

  const handleRemoveFromNetwork = async () => {
    await dispatch(removeProviderFromNetwork(selectedProvider.id));
    message.success(`${selectedProvider.name} removed from your network`);
    handleCancel();
  };

  return (
    <Modal
      title='Remove Provider from Network'
      okText='Yes, remove'
      className='dashboard-mode-modal'
      open={isModalVisible}
      onOk={handleRemoveFromNetwork}
      okType='danger'
      confirmLoading={removeProviderLoading}
      onCancel={() => handleCancel()}
    >
      <Content>
        <RemoveProviderIcon />

        <p>
          Are you sure you want to remove {selectedProvider.name} from your
          provider network?
        </p>
      </Content>
    </Modal>
  );
}

export default RemoveProviderModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  p {
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
  }
`;
