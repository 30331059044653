import styled from 'styled-components';

const PasswordAPIRequestModalWrapper = styled.div`
    .ant-modal {
        border: none;
        padding: 5px 0px;
        border-bottom: 1px solid #BFCAD0;
        border-radius: 0px;
        &:focus {
            box-shadow: none;
        }
    }
`;

const UploadWrapper = styled.div`
    margin-top: 20px;
    .ant-upload {
        border: none;
        &.ant-upload-btn {
            background: #F3F8FB;
            border: 1px dashed #0f9af0;
            p.ant-upload-hint {
                font-family: "DM Sans";
                margin-top: 20px;
                font-weight: normal;
                font-size: 13px;
                text-align: center;
                color: #4e667e;
            }
        }
    }
`;

export { PasswordAPIRequestModalWrapper, UploadWrapper }