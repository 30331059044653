import { Drawer } from "antd";
import { cloneElement, useState, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Navbar from "../../component/Navbar";
import Sidebar from "../../component/Sidebar";
import { DashboardWrapper, Container, MainWrapper } from "./styles";
import AppIcon from "../../assets/images/icon.svg";
import SwitchModeModal from "../../component/SwitchModeModal";

const DashboardLayout = (props: any) => {
  const location = useLocation();
  const [isChatPaneVisible, setIsChatPaneVisible] = useState(false);
  const [isEncounterFormVisible, setIsEncounterFormVisible] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(false);
  const [isDashboardModeModalVisible, setIsDashboardModeModalVisible] = useState(false);
  const dashboardMode = window.localStorage.getItem("pneumaDashboardMode") || "integration";

  useEffect(() => {
    if (!dashboardMode) {
      setIsDashboardModeModalVisible(true);
    }
  }, [dashboardMode])

  useEffect(() => {
    setOpenedMenu(false);
  }, [location])

  return (
    <DashboardWrapper>
      <div>
        <Navbar setOpenedMenu={setOpenedMenu} />
      </div>
      <Container>
        <Sidebar dashboardMode={dashboardMode} setIsDashboardModeModalVisible={setIsDashboardModeModalVisible} />
        <Drawer
          className="sidebar-drawer"
          title={<div><img src={AppIcon} alt="icon" /></div>}
          placement={'left'}
          closable={true}
          width={"auto"}
          onClose={() => setOpenedMenu(false)}
          visible={openedMenu}
          key={'left'}
        >
          <Sidebar dashboardMode={dashboardMode} setIsDashboardModeModalVisible={setIsDashboardModeModalVisible} /> 
        </Drawer>
        <SwitchModeModal
          handleCancel={() => setIsDashboardModeModalVisible(false)}
          isModalVisible={isDashboardModeModalVisible}
        />
        <MainWrapper>
          {cloneElement(props.children, {
            isChatPaneVisible,
            setIsChatPaneVisible,
            isEncounterFormVisible,
            setIsEncounterFormVisible,
          })}
        </MainWrapper>
      </Container>
    </DashboardWrapper>
  );
};

const DashboardLayoutRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const ACCESS_TOKEN = window.localStorage.getItem("accessToken");
  const dashboardMode = window.localStorage.getItem("pneumaDashboardMode") || "integration";
  return ACCESS_TOKEN ? (
    <Route
      {...rest}
      render={(matchProps) => (
        <DashboardLayout>
          <Component {...matchProps} dashboardMode={dashboardMode} />
        </DashboardLayout>
      )}
    />
  ) : (
    <Redirect to="/" />
  );
};

export default DashboardLayoutRoute;
