import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { Table, Row, Col } from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";

import Card from "component/Card";
import Button from "component/Button";
import LabRequestsDrawer from "../LabRequestsDrawer";
import {
  fetchAllLabRequests,
  fetchAllOtherServices,
  labRequestSelector
} from "redux/reducers/labRequests";
import AvatarImg from "component/Avatar";
import Search from "component/Search";

import { LabRequestsDrawerHeader } from "../LabRequestsDrawer/styles";
import { LabRequestsTableWrapper, LabRequestsTableBody } from "./styles";

interface LabRequestsTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
  dashboardMode?: string;
}

const LabRequestsTable = ({
  count,
  hasPagination = true,
  marginTop = 20,
  dashboardMode
}: LabRequestsTableProps) => {
  const dispatch = useDispatch();
  const [selectedTag, setSelectedTag] = useState("Diagnostics");
  const [keyword, setKeyword] = useState("");
  const { labRequests, isLabRequestsLoading } = useSelector(labRequestSelector);
  const [labRequestsState, setLabRequestsState] = useState<any[]>([]);
  const [selectedLabRequest, setSelectedLabRequest] = useState(null);
  const [isLabRequestsDrawerOpen, setIsLabRequestsDrawerOpen] = useState(false);

  useEffect(() => {
    if (selectedTag === "Diagnostics") {
      dispatch(fetchAllLabRequests());
    } else {
      dispatch(fetchAllOtherServices());
    }
  }, [dispatch, selectedTag]);

  useEffect(() => {
    setLabRequestsState(labRequests);
  }, [labRequests]);

  const handleOpenDrawer = (item: any) => {
    setSelectedLabRequest(item);
    setIsLabRequestsDrawerOpen(true);
  };

  const columns = [
    {
      title: "User",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      sorter: (a: any, b: any) =>
        a?.user?.first_name.toLowerCase() < b?.user?.first_name.toLowerCase()
          ? -1
          : a?.user?.first_name.toLowerCase() >
            b?.user?.first_name.toLowerCase()
          ? 1
          : 0,
      render: (_: string, record: any) => {
        const { first_name, last_name } = record?.user || {};
        return (
          <div className='flex align-center' style={{ alignItems: "center" }}>
            <AvatarImg
              img=''
              style={{ marginRight: 10 }}
              fallbackText={`${first_name} ${last_name}`}
            />
            <div>
              <p style={{ margin: 0 }}>
                {first_name} {last_name}
              </p>
            </div>
          </div>
        );
      }
    },
    {
      title: "Request ID",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return <p>{id}</p>;
      }
    },
    {
      title: "Service Provider",
      dataIndex: "laboratory",
      key: "laboratory",
      render: (lab: any, record: any) => {
        const { name: labName } = record?.laboratory || {};
        const { business_name } = record?.provider || {};
        return <p>{labName || business_name}</p>;
      }
    },
    {
      title: "Created on",
      dataIndex: "schedule",
      key: "schedule",
      render: (_: Date, record: any) => {
        return (
          <>
            <p>{moment(record.created_at).format("LL")}</p>
            <span className='subtext'>
              {moment(record.created_at).format("hh:mm a")}
            </span>
          </>
        );
      }
    },
    {
      title: "Price",
      dataIndex: "total_price",
      key: "total_price",
      align: "center" as "center",
      render: (total_price: number) => {
        return (
          <p>
            ₦
            {(total_price ? total_price / 100 : 0)?.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </p>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center" as "center",
      render: (status: string) => {
        return (
          <p className='tags' style={{ textTransform: "capitalize" }}>
            {status}
          </p>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center" as "center",
      render: (_: string, record: any) => {
        return (
          <Button type='secondary' onClick={() => handleOpenDrawer(record)}>
            View {selectedTag === "Diagnostics" ? " " : "request"} {"details"}
          </Button>
        );
      }
    }
  ];

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _labRequests = [...labRequests];
    setLabRequestsState(
      _labRequests.filter((item) => {
        const { user } = item;
        const fullName = `${user.first_name} ${user.last_name}`;
        return fullName.toLowerCase().includes(keyword.toLowerCase());
      })
    );
  };

  const handleChange = (tag: any) => {
    setSelectedTag(tag);
  };

  const tagsData = ["Diagnostics", "Service Bookings"];
  const totalCount =
    labRequestsState.length === labRequests.length
      ? `${labRequestsState.length}`
      : `${labRequestsState.length}/${labRequests.length}`;

  const dataSource = count
    ? labRequestsState.slice(0, count)
    : labRequestsState;
  return (
    <>
      {!count && (
        <Row justify='space-between' align='middle'>
          <Col xs={24} md={12}>
            <h3>
              {count ? "Recent Requests" : "Service Requests"}{" "}
              <small>({totalCount})</small>
            </h3>
          </Col>
          <Col xs={24} md={12}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div style={{ flexGrow: 1 }}>
                <Search
                  placeholder="Search by user's name"
                  onChange={handleSearch}
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <LabRequestsTableWrapper>
          <LabRequestsDrawerHeader>
            <span className='title'>Filter by category:</span>
            {tagsData.map((tag) => (
              <CheckableTag
                key={tag}
                checked={selectedTag === tag}
                onChange={() => handleChange(tag)}
              >
                {tag}
              </CheckableTag>
            ))}
          </LabRequestsDrawerHeader>
          <LabRequestsTableBody>
            <Table
              dataSource={dataSource}
              columns={columns}
              scroll={
                !isLabRequestsLoading && dataSource.length > 0 ? { x: 700 } : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isLabRequestsLoading}
              pagination={{ position: ["bottomRight"], showLessItems: true }}
            />
          </LabRequestsTableBody>
        </LabRequestsTableWrapper>
        <LabRequestsDrawer
          isVisible={isLabRequestsDrawerOpen}
          isDiagnostics={selectedTag === "Diagnostics"}
          selectedLabRequest={selectedLabRequest}
          onClose={() => setIsLabRequestsDrawerOpen(false)}
        />
      </Card>
    </>
  );
};

export default LabRequestsTable;
