import styled, { css } from 'styled-components';

interface InputProps {
    marginBottom: number;
    mode?: string;
    type?: string;
}

const InputWrapper = styled.div<InputProps>`
    label, .ant-form-item-label {
        font-weight: 500;
        font-size: 13px;
        text-align: left;
        color: #333;
        padding: 0px;
        margin-bottom: 5px;
    }
    ${props => props.type === "password" ? `
        .ant-input-affix-wrapper.ant-input-password {
            border: none;
            padding: ${props.mode === "normal" ? "10px" : "5px 0px" };
            border-bottom: ${props.mode === "normal" ? "" : "1px solid #BFCAD0" };
            border: ${props.mode === "normal" ? "1px solid #BFCAD0" : "" };
            border-radius: ${props.mode === "normal" ? "8px" : "0px" };
            &:focus {
                box-shadow: none;
                ${props.mode === "normal" ? "border: 1px solid #0f9af0" : "" };
            }
        }
    ` : `
        .ant-input {
            border: none;
            padding: ${props.mode === "normal" ? "10px" : "5px 0px" };
            border-bottom: ${props.mode === "normal" ? "" : "1px solid #BFCAD0" };
            border: ${props.mode === "normal" ? "1px solid #BFCAD0" : "" };
            border-radius: ${props.mode === "normal" ? "8px" : "0px" };
            &:focus {
                box-shadow: none;
                ${props.mode === "normal" ? "border: 1px solid #0f9af0" : "" };
            }
        }
    `}
    /* removing the ring around the password input */
    .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
        box-shadow: none !important;
    }
    .ant-input-suffix {
        .anticon {
            font-size: 19px;
        }
    }
    .ant-input-password {
        border: none;
        padding: 5px 0px;
        border-bottom: 1px solid #BFCAD0;
        border-radius: 0px;
        &:focus {
            box-shadow: none;
        }
    }
    .ant-form-item { 
        margin-bottom: ${(props) => `${!isNaN(props.marginBottom) ? props.marginBottom : 24}px`};
    }
    .ant-form-item-explain, .ant-form-item-extra {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        /* color: rgba(0, 46, 70, 0.75); */
    }

    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector, .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
        box-shadow: none;
    }
`;

export { InputWrapper }