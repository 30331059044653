import moment from 'moment';

const getAge = (dob: any) => {
    const ageInYears = dob ? moment().diff(moment(dob, "DD/MM/YYYY"), "years") : null;
    if (ageInYears || ageInYears === 0) {
        return ageInYears === 0 
            ? `${moment().diff(moment(dob, "DD/MM/YYYY"), "months")} mo`
            : ageInYears > 0 
                ? `${ageInYears}yrs` 
                : '—';
    } 
    return "—";
}

export default getAge;