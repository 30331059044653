import { Col, Drawer, Row } from "antd";
import { LabRequestsDrawerWrapper, LabRequestsDrawerBody } from "./styles";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleLabRequest,
  fetchAllOtherServices,
  fetchAllLabRequests,
  cancelServiceOrder,
  cancelLabRequest,
  labRequestSelector,
} from "../../../../redux/reducers/labRequests";
import Loader from "../../../../component/Loader";
import Button from "../../../../component/Button";
import moment from "moment";

const LabRequestsDrawer = (props: any) => {
  const { selectedLabRequest, isVisible, isDiagnostics, onClose } = props;
  const dispatch = useDispatch();
  const {
    labRequest,
    isLabRequestLoading,
    isCancelServiceOrderLoading,
    isCancelLabRequestLoading,
  } = useSelector(labRequestSelector);

  useEffect(() => {
    if (selectedLabRequest && isDiagnostics) {
      dispatch(fetchSingleLabRequest(selectedLabRequest.id));
    }
  }, [dispatch, selectedLabRequest, isDiagnostics]);

  const { investigations } = labRequest || {};

  async function handleCancelServiceOrder(id: string) {
    await dispatch(cancelServiceOrder(id));
    await dispatch(fetchAllOtherServices());
    onClose();
  }

  async function handleCancelLabRequest(id: string) {
    await dispatch(cancelLabRequest(id));
    await dispatch(fetchAllLabRequests());
    onClose();
  }

  return (
    <Drawer
      title={isDiagnostics ? `Investigations` : "Plan Details"}
      width='450'
      className='lab-request-drawer'
      placement='right'
      closable={true}
      onClose={onClose}
      visible={isVisible}
    >
      <LabRequestsDrawerWrapper>
        <LabRequestsDrawerBody>
          {!isDiagnostics && !isCancelServiceOrderLoading ? (
            <div>
              <p>Name: {selectedLabRequest?.plan?.name}</p>
              <p className='price'>
                Price: NGN{" "}
                {(selectedLabRequest?.total_price
                  ? selectedLabRequest.total_price / 100
                  : 0
                ).toLocaleString()}
              </p>
              <p>Schedule: {selectedLabRequest?.plan?.schedule}</p>
              {selectedLabRequest?.metadata?.preferred_date &&
                selectedLabRequest?.status !== "canceled" && (
                  <p>
                    Preferred Date:{" "}
                    {moment(
                      selectedLabRequest.metadata.preferred_date
                        .split("/")
                        .reverse()
                        .join(",")
                    ).format("LL")}
                  </p>
                )}
              <p>Unit: {selectedLabRequest?.plan?.unit}</p>
              <p>Quantity: {selectedLabRequest?.number}</p>

              {selectedLabRequest?.status !== "canceled" && (
                <Button
                  type='secondary'
                  onClick={() =>
                    handleCancelServiceOrder(selectedLabRequest?.id)
                  }
                >
                  Cancel Order
                </Button>
              )}
            </div>
          ) : isLabRequestLoading || isCancelLabRequestLoading ? (
            <Loader />
          ) : (
            investigations?.map((investigation: any, index: number) => {
              const {
                name,
                description,
                sample_bottle,
                price,
                turn_around_time,
                category,
                status,
                id,
              } = investigation || {};
              return (
                <div className='investigation-item'>
                  <p className='price'>
                    NGN {(price ? price / 100 : 0).toLocaleString()}
                  </p>
                  <p className='name'>{name}</p>
                  <p className='description'>{description}</p>
                  <Row gutter={20}>
                    <Col span={24}>
                      <span className='category tag'>Category: {category}</span>
                    </Col>
                    <Col span={24}>
                      <span className='turn_around_time tag'>
                        {" "}
                        <ClockCircleOutlined /> {turn_around_time}
                      </span>
                      <span className='category tag'>
                        Sample Bottle: {sample_bottle}
                      </span>
                    </Col>
                  </Row>

                  {status !== "canceled" && (
                    <Button onClick={() => handleCancelLabRequest(id)}>
                      Cancel Request
                    </Button>
                  )}
                </div>
              );
            })
          )}
        </LabRequestsDrawerBody>
      </LabRequestsDrawerWrapper>
    </Drawer>
  );
};

export default LabRequestsDrawer;
