import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunk } from '../store'
import http from '../../utils/api'
import { fetchSingleConsult } from './consults'
import { message } from 'antd'

export interface InvestigationError {
    message: string
}

export interface InvestigationState {
    investigation: any
    investigations: Array<any>
    isInvestgationsLoading: boolean
    addInvestgationsLoading: boolean
    deleteInvestgationsLoading: boolean
    investigationsError: InvestigationError
    addInvestgationsError: InvestigationError
    deleteInvestgationsError: InvestigationError
}

export const initialState: InvestigationState = {
    investigation: null,
    investigations: [],
    isInvestgationsLoading: false,
    addInvestgationsLoading: false,
    deleteInvestgationsLoading: false,
    investigationsError: { message: '' },
    addInvestgationsError: { message: '' },
    deleteInvestgationsError: { message: '' },
}

export const investigationsSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        fetchInvestgationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.isInvestgationsLoading = payload
        },
        fetchInvestgationsSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
            state.investigations = payload;
        },
        fetchInvestgationsFailed: (state, { payload }: PayloadAction<InvestigationError>) => {
            state.investigationsError = payload;
        },
        addInvestgationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.addInvestgationsLoading = payload
        },
        addInvestgationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.investigations = payload;
        },
        addInvestgationsFailed: (state, { payload }: PayloadAction<InvestigationError>) => {
            state.addInvestgationsError = payload;
        },
        deleteInvestgationsLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.deleteInvestgationsLoading = payload
        },
        deleteInvestgationsSuccess: (state, { payload }: PayloadAction<Boolean>) => {
            // state.investigations = payload;
        },
        deleteInvestgationsFailed: (state, { payload }: PayloadAction<InvestigationError>) => {
            state.deleteInvestgationsError = payload;
        },
    },
})

export const { 
    fetchInvestgationsLoading, fetchInvestgationsSuccess, fetchInvestgationsFailed, 
    addInvestgationsLoading, addInvestgationsSuccess, addInvestgationsFailed,
    deleteInvestgationsLoading, deleteInvestgationsSuccess, deleteInvestgationsFailed
} = investigationsSlice.actions;
export const investigationSelector = (state: { investigations: InvestigationState }) => state.investigations;
export default investigationsSlice.reducer;


/** Actions */

export const fetchAllInvestgations = (): AppThunk => async (dispatch) => {
    dispatch(fetchInvestgationsLoading(true))
    await http.get('/investigations')
        .then((res) => {
            const investigations = res?.data?.data;
            dispatch(fetchInvestgationsSuccess(investigations))
        })
        .catch((err) => {
            const message = { message: err?.response?.data?.message };
            dispatch(fetchInvestgationsFailed(message));
        })
    dispatch(fetchInvestgationsLoading(false));
}

export const addConsultInvestgations = (consultId: any, payload: any): AppThunk => async (dispatch) => {
    dispatch(addInvestgationsLoading(true))
    await http.post(`/consults/${consultId}/investigations`, payload)
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(addInvestgationsSuccess(examinations))
            message.success("Investigations Added to Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(addInvestgationsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(addInvestgationsLoading(false));
}

export const deleteConsultInvestgations = (consultId: any, investigationIds: any): AppThunk => async (dispatch) => {
    dispatch(deleteInvestgationsLoading(true))
    const payload: any = {
        investigations: investigationIds
    }
    await http.delete(`/consults/${consultId}/investigations`, { data: payload })
        .then((res) => {
            const examinations = res?.data?.data;
            dispatch(deleteInvestgationsSuccess(examinations))
            message.success("Investigations Deleted from Consult Successfully");
            dispatch(fetchSingleConsult(consultId));
        })
        .catch((err) => {
            const _message = { message: err?.response?.data?.message || "An error occurred" };
            dispatch(deleteInvestgationsFailed(_message));
            message.error(err?.response?.data?.message || "An error occurred");
        })
    dispatch(deleteInvestgationsLoading(false));
}

