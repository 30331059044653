import styled from 'styled-components';


const DashboardModeWrapper = styled.div`
    padding: 30px 0px 50px;
    .mode-item {
        text-align: center;
        border: 2px solid #f2f2f2;
        padding: 30px 20px;
        border-radius: 6px;
        position: relative;
        cursor: pointer;
        box-shadow: 0px 1px 5px rgba(0, 120, 226, 0.1);
        &:hover {
        }
        .check {
            position: absolute;
            top: 10px;
            right: 5px;
        }
        &.active {
            border: 2px solid #0f9af0;
        }
        .icon svg {
            width: 40px;
            margin-bottom: 20px;
        }
        h3 {
            color: #0f9af0;
        }
        p {
            font-size: 12px;
        }
    }
`;

export { DashboardModeWrapper }