import { Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { ReactComponent as ProvidersIcon } from "assets/icons/add-providers-icon.svg";

import {
  addProviderToNetwork,
  providersSelector
} from "redux/reducers/providers";

interface Props {
  isModalVisible: boolean;
  handleCancel: () => void;
  selectedProvider: { name: string; id: string };
}

function AddProviderModal({
  isModalVisible,
  handleCancel,
  selectedProvider
}: Props) {
  const dispatch = useDispatch();
  const { addProviderLoading } = useSelector(providersSelector);

  const handleAddToNetwork = async () => {
    await dispatch(addProviderToNetwork(selectedProvider.id));
    message.success(`${selectedProvider.name} added to your network`);
    handleCancel();
  };

  return (
    <Modal
      title='Add Provider to Network'
      okText='Add to network'
      className='dashboard-mode-modal'
      open={isModalVisible}
      onOk={handleAddToNetwork}
      confirmLoading={addProviderLoading}
      onCancel={() => handleCancel()}
    >
      <Content>
        <ProvidersIcon />
        <p>
          Please note that adding {selectedProvider.name} to your provider
          network will result in an immediate charge for this month.
        </p>
      </Content>
    </Modal>
  );
}

export default AddProviderModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;

  p {
    text-align: center;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    max-width: 360px;
    margin: 0 auto;
  }
`;
