import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './redux/store';

// import firebase from 'firebase/app';
import 'firebase/auth/';
import 'firebase/firestore';
import firebase from 'firebase';
import 'firebase/performance';

// production firebase instantiation
const firebaseConfigProd = {
  apiKey: "AIzaSyCQRJIrYTwK9fw4lrG2QUpTECnj_9VXPu4",
  authDomain: "integration-dashboard-prod.firebaseapp.com",
  projectId: "integration-dashboard-prod",
  storageBucket: "integration-dashboard-prod.appspot.com",
  messagingSenderId: "152236647434",
  appId: "1:152236647434:web:a0b08131f34e565d4bebf3",
  measurementId: "G-C71XEBGPCR"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfigProd);
}


// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
