import { Col, Drawer, Row } from "antd";
import Card from "../../../../component/Card";
import { FormDrawerWrapper, FormDrawerBody, FormItemWrapper } from "./styles";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import { fetchAllForms, formSelector } from "../../../../redux/reducers/forms";
import Button from "../../../../component/Button";

const FormDrawer = (props: any) => {
  const dispatch = useDispatch();
  const { selectedForm, isVisible, onClose } = props;
  const { forms, isFormsLoading } = useSelector(formSelector);

  const { name, questions } = selectedForm || {};

  return (
    <Drawer
      title={`Questions for ${name}`}
      width="450"
      className="form-drawer"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={isVisible}
    >
      <FormDrawerWrapper>
        <FormDrawerBody>
            {
              questions?.map((question: any, index: number) => {
                const { text, response } = question || {};
                return (
                  <div className="question-item">
                    <p>{index + 1}. {text}</p>
                    {Array.isArray(response) ? response.map((item: any) => {
                      return <div className="tag">{item.value}</div>
                    }) : <div className="tag">FREE TEXT INPUT</div>}
                  </div>
                )
              })
            }
        </FormDrawerBody>
      </FormDrawerWrapper>
    </Drawer>
  );
};

export default FormDrawer;
