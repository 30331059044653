import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message as AntdMessage } from "antd";

import { AppThunk } from "../store";
import http, { httpSecret } from "utils/api";
import { SingleProvider, SingleLocalProvider } from "utils/apiTypes";

interface ProvidersError {
  message: string;
}

interface TotalBookingsCountType {
  total: number;
  accepted: number;
  canceled: number;
  started: number;
  pending: number;
  completed: number;
}

interface ProvidersState {
  allProviders: Array<SingleProvider>;
  isAllProvidersLoading: boolean;
  allProvidersError: ProvidersError;

  myProviders: Array<SingleLocalProvider>;
  isMyProvidersLoading: boolean;
  myProvidersError: ProvidersError;

  addProviderLoading: boolean;
  addProviderError: ProvidersError;

  removeProviderLoading: boolean;
  removeProviderError: ProvidersError;

  totalBookingsCount: TotalBookingsCountType;
  getBookingsCountLoading: boolean;
  getBookingsCountError: ProvidersError;

  singleProvider: SingleProvider | undefined;
  isSingleProviderLoading: boolean;
  singleProviderError: ProvidersError;
}

const initialState: ProvidersState = {
  allProviders: [],
  isAllProvidersLoading: false,
  allProvidersError: { message: "" },

  myProviders: [],
  isMyProvidersLoading: false,
  myProvidersError: { message: "" },

  addProviderLoading: false,
  addProviderError: { message: "" },

  removeProviderLoading: false,
  removeProviderError: { message: "" },

  totalBookingsCount: {
    total: 0,
    accepted: 0,
    canceled: 0,
    started: 0,
    pending: 0,
    completed: 0
  },
  getBookingsCountLoading: false,
  getBookingsCountError: { message: "" },

  singleProvider: undefined,
  isSingleProviderLoading: false,
  singleProviderError: { message: "" }
};

export const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    // ALL PROVIDERS
    fetchAllProvidersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isAllProvidersLoading = payload;
    },
    fetchAllProvidersSuccess: (
      state,
      { payload }: PayloadAction<Array<SingleProvider>>
    ) => {
      state.allProviders = payload;
    },
    fetchAllProvidersFailed: (
      state,
      { payload }: PayloadAction<ProvidersError>
    ) => {
      state.allProvidersError = payload;
    },

    // NEW PROVIDERS
    fetchMyProvidersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isMyProvidersLoading = payload;
    },
    fetchMyProvidersSuccess: (
      state,
      { payload }: PayloadAction<Array<SingleLocalProvider>>
    ) => {
      state.myProviders = payload;
    },
    fetchMyProvidersFailed: (
      state,
      { payload }: PayloadAction<ProvidersError>
    ) => {
      state.myProvidersError = payload;
    },

    // SINGLE PROVIDER
    fetchSingleProviderLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSingleProviderLoading = payload;
    },
    fetchSingleProviderSuccess: (
      state,
      { payload }: PayloadAction<SingleProvider>
    ) => {
      state.singleProvider = payload;
    },
    fetchSingleProviderFailed: (
      state,
      { payload }: PayloadAction<ProvidersError>
    ) => {
      state.singleProviderError = payload;
    },

    // ADD PROVIDER
    addProviderLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.addProviderLoading = payload;
    },

    addProviderFailed: (state, { payload }: PayloadAction<ProvidersError>) => {
      state.addProviderError = payload;
    },

    // REMOVE PROVIDER
    removeProviderLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.removeProviderLoading = payload;
    },

    removeProviderFailed: (
      state,
      { payload }: PayloadAction<ProvidersError>
    ) => {
      state.removeProviderError = payload;
    },

    // BOOKINGS COUNT
    getBookingsCountLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.getBookingsCountLoading = payload;
    },
    getBookingsCountSuccess: (
      state,
      { payload }: PayloadAction<TotalBookingsCountType>
    ) => {
      state.totalBookingsCount = payload;
    },
    getBookingsCountFailed: (
      state,
      { payload }: PayloadAction<ProvidersError>
    ) => {
      state.getBookingsCountError = payload;
    }
  }
});

export const {
  fetchMyProvidersSuccess,
  fetchMyProvidersLoading,
  fetchMyProvidersFailed,
  fetchAllProvidersSuccess,
  fetchAllProvidersLoading,
  fetchAllProvidersFailed,
  fetchSingleProviderFailed,
  fetchSingleProviderLoading,
  fetchSingleProviderSuccess,
  addProviderFailed,
  addProviderLoading,
  removeProviderFailed,
  removeProviderLoading,
  getBookingsCountFailed,
  getBookingsCountLoading,
  getBookingsCountSuccess
} = providersSlice.actions;

export const providersSelector = (state: { providers: ProvidersState }) =>
  state.providers;
export default providersSlice.reducer;

// Actions
export const fetchAllProviders = (): AppThunk => async (dispatch) => {
  dispatch(fetchAllProvidersLoading(true));
  try {
    const res = await http.get(`/services/providers`);
    const providers: SingleProvider[] = res.data.data;
    dispatch(fetchAllProvidersSuccess(providers));
  } catch (err: any) {
    const message = { message: err?.response?.data?.message };
    dispatch(fetchAllProvidersFailed(message));
  } finally {
    dispatch(fetchAllProvidersLoading(false));
  }
};

export const fetchSingleProvider =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(fetchSingleProviderLoading(true));
    try {
      const res = await http.get(`/services/providers/${id}`);
      const provider: SingleProvider = res.data.data;
      dispatch(fetchSingleProviderSuccess(provider));
    } catch (err: any) {
      const message = { message: err?.response?.data?.message };
      dispatch(fetchSingleProviderFailed(message));
    } finally {
      dispatch(fetchSingleProviderLoading(false));
    }
  };

export const fetchAllMyProviders = (): AppThunk => async (dispatch) => {
  dispatch(fetchMyProvidersLoading(true));

  try {
    const res = await httpSecret.get(`/network`);
    const myProviders: SingleLocalProvider[] = res.data.data.data;
    dispatch(fetchMyProvidersSuccess(myProviders));
  } catch (err: any) {
    const message = { message: err?.response?.data?.message };
    dispatch(fetchMyProvidersFailed(message));
  } finally {
    dispatch(fetchMyProvidersLoading(false));
  }
};

export const addProviderToNetwork =
  (providerId: string): AppThunk =>
  async (dispatch) => {
    dispatch(addProviderLoading(true));

    try {
      await http.post(`/network`, {
        service_provider: providerId
      });
      dispatch(fetchAllMyProviders());
      dispatch(fetchAllProviders());
    } catch (err: any) {
      const message = { message: err?.response?.data?.message };
      AntdMessage.error(
        message.message || "Something went wrong. Please try again!"
      );
      dispatch(addProviderFailed(message));
    } finally {
      dispatch(addProviderLoading(false));
    }
  };

export const removeProviderFromNetwork =
  (provider: string): AppThunk =>
  async (dispatch) => {
    dispatch(removeProviderLoading(true));

    try {
      await http.delete(`/network/${provider}`);
      dispatch(fetchAllMyProviders());
      dispatch(fetchAllProviders());
    } catch (err: any) {
      const message = { message: err?.response?.data?.message };
      AntdMessage.error(
        message.message || "Something went wrong. Please try again!"
      );
    } finally {
      dispatch(removeProviderLoading(false));
    }
  };

export const getNetworkBookingsCount = (): AppThunk => async (dispatch) => {
  dispatch(getBookingsCountLoading(true));

  try {
    const res = await httpSecret.get(`services/orders/count`);
    const count: TotalBookingsCountType = res.data.data;
    dispatch(getBookingsCountSuccess(count));
  } catch (err: any) {
    const message = { message: err?.response?.data?.message };
    dispatch(getBookingsCountFailed(message));
  } finally {
    dispatch(getBookingsCountLoading(false));
  }
};
