import { Col, Empty, Progress, Row } from "antd";
import Card from "../../component/Card";
import UserIcon from "../../assets/icons/user.svg";
import {
  ProfileWrapper,
  ContentWrapper,
  ReviewCardsWrapper,
  ReviewMainCardsWrapper,
} from "./styles";
import StarActive from "../../assets/icons/star-active.svg";
import StarInactive from "../../assets/icons/star.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllReviews, reviewSelector } from "../../redux/reducers/reviews";
import Loader from "../../component/Loader";
import moment from "moment";
import Avatar from "antd/lib/avatar/avatar";
import AvatarImg from "../../component/Avatar";

const Reviews = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllReviews());
  }, [dispatch]);

  const { reviews: reviewsObject, isReviewsLoading } =
    useSelector(reviewSelector);

  const { avg = 0, ratings = [] }: { avg: number; ratings: any[] } =
    reviewsObject || {};

  const starsActive = Array.from({ length: Math.floor(avg) }, (v, k) => k + 1);
  const starsInactive = Array.from(
    { length: 5 - Math.floor(avg) },
    (v, k) => k + 1
  );

  const ratingKeys: any = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };

  ratings.forEach((item) => {
    ratingKeys[item.rating] += item.rating;
  });

  return (
    <ProfileWrapper>
      <Row>
        <Col span={24}>
          <ContentWrapper>
            <Card marginBottom={15} padding={40}>
              <Row className="reviews-header">
                <Col span={24}>
                  <p>Feedback and Reviews</p>
                </Col>
              </Row>
              <Row gutter={40}>
                <Col xs={24} md={6} className="reviews-lhs">
                  <h1>{(avg || 0).toFixed(1)}</h1>
                  <span className="stars">
                    {starsActive.map((item) => (
                      <img key={item} src={StarActive} alt="star" />
                    ))}
                    {starsInactive.map((item) => (
                      <img key={item} src={StarInactive} alt="star" />
                    ))}
                  </span>
                  <p>Based on {ratings.length} ratings</p>

                  <div className="progress">
                    <Row className="progress-item">
                      <Col span={2}>
                        <span>5</span>
                      </Col>
                      <Col span={22}>
                        <Progress
                          percent={ratingKeys[5]}
                          size="small"
                          showInfo={false}
                        />
                      </Col>
                    </Row>
                    <Row className="progress-item">
                      <Col span={2}>
                        <span>4</span>
                      </Col>
                      <Col span={22}>
                        <Progress
                          percent={ratingKeys[4]}
                          size="small"
                          showInfo={false}
                        />
                      </Col>
                    </Row>
                    <Row className="progress-item">
                      <Col span={2}>
                        <span>3</span>
                      </Col>
                      <Col span={22}>
                        <Progress
                          percent={ratingKeys[3]}
                          size="small"
                          showInfo={false}
                        />
                      </Col>
                    </Row>
                    <Row className="progress-item">
                      <Col span={2}>
                        <span>2</span>
                      </Col>
                      <Col span={22}>
                        <Progress
                          percent={ratingKeys[2]}
                          size="small"
                          showInfo={false}
                        />
                      </Col>
                    </Row>
                    <Row className="progress-item">
                      <Col span={2}>
                        <span>1</span>
                      </Col>
                      <Col span={22}>
                        <Progress
                          percent={ratingKeys[1]}
                          size="small"
                          showInfo={false}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={24} md={18} className="reviews-rhs">
                  <Row style={{ marginTop: "25px" }} gutter={20}>
                    {isReviewsLoading ? (
                      <Loader />
                    ) : ratings.length === 0 ? (
                      <Row align="middle" justify="center" style={{height: '300px', width: '100%'}}>
                        <Empty />
                      </Row>
                    ) : (
                      ratings.map(({ text, rating, img, user, created_at }) => {
                        const { first_name, last_name } = user;
                        const starsActive = Array.from(
                          { length: Math.floor(rating) },
                          (v, k) => k + 1
                        );
                        const starsInactive = Array.from(
                          { length: 5 - Math.floor(rating) },
                          (v, k) => k + 1
                        );
                        return (
                          <Col xs={24} md={12}>
                            <ReviewMainCardsWrapper>
                              <Row>
                                <AvatarImg style={{marginRight: 10}} img={img} fallbackText={`${first_name} ${last_name}`} />
                                <div className="title">
                                  <p>{first_name} {last_name}</p>
                                  <span>{moment(created_at).format('LL')}</span>
                                </div>
                              </Row>
                              <p className="content">{text}</p>
                              <span className="stars">
                                {starsActive.map((item) => (
                                  <img key={item} src={StarActive} alt="star" />
                                ))}
                                {starsInactive.map((item) => (
                                  <img key={item} src={StarInactive} alt="star" />
                                ))}
                              </span>
                            </ReviewMainCardsWrapper>
                          </Col>
                        );
                      })
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
          </ContentWrapper>
        </Col>
      </Row>
    </ProfileWrapper>
  );
};

export default Reviews;
