import { Col, Row, Select, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddUserModal from "component/AddUserModal";
import AvatarImg from "component/Avatar";
import Button from "component/Button";
import Card from "component/Card";
import {
  fetchAllUsers,
  userSelector,
  fetchAllUsersCount
} from "redux/reducers/users";
import getAge from "utils/utils";
import { UsersTableBody, UsersTableWrapper } from "./styles";

const UsersTable = ({ dashboardMode }: any) => {
  const dispatch = useDispatch();
  const [usersState, setUsersState] = useState<any[]>([]);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const { users, isUsersLoading, usersCount } = useSelector(userSelector);

  useEffect(() => {
    dispatch(fetchAllUsers());
    dispatch(fetchAllUsersCount());
  }, [dispatch]);

  useEffect(() => {
    setUsersState(users.data);
  }, [users]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "first_name",
      sorter: (a: any, b: any) =>
        a.first_name.toLowerCase() < b.first_name.toLowerCase()
          ? -1
          : a.first_name.toLowerCase() > b.first_name.toLowerCase()
          ? 1
          : 0,
      render: (_: string, record: any) => {
        const { first_name, last_name, dob } = record || {};
        return (
          <div className='flex'>
            <AvatarImg
              img=''
              style={{ marginRight: 10 }}
              fallbackText={`${first_name} ${last_name}`}
            />
            <div>
              <p>
                {first_name} {last_name}
              </p>
              <span>{getAge(dob)}</span>
            </div>
          </div>
        );
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) =>
        a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1,
      render: (email: string) => {
        return <p>{email}</p>;
      }
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (phone: string) => {
        return <p>{phone || "—"}</p>;
      }
    },
    {
      title: "Added on",
      dataIndex: "schedule",
      key: "schedule",
      render: (created_at: Date, record: any) => {
        return (
          <>
            <p>{moment(record.created_at).format("LL")}</p>
            <span>{moment(record.created_at).format("hh:mm a")}</span>
          </>
        );
      }
    }
  ];

  const _usersState = usersState;

  const [selectedRange, setSelectedRange] = useState(10);

  return (
    <>
      <Row justify='space-between' align='middle'>
        <Col xs={24} md={12}>
          <h3>
            Users <small>({usersCount})</small>
          </h3>
        </Col>
        <Col xs={24} md={12}>
          <div style={{ display: "flex" }}>
            {dashboardMode === "connect" && (
              <Button
                style={{ marginLeft: 10, height: "40px" }}
                onClick={() => setIsAddUserModalVisible(true)}
                type='primary'
              >
                Add New User
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <UsersTableWrapper>
          <UsersTableBody>
            <Table
              dataSource={_usersState}
              columns={columns}
              scroll={
                !isUsersLoading && usersState.length > 0 ? { x: 700 } : {}
              }
              loading={isUsersLoading}
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              pagination={{
                style: { display: "none" },
                pageSize: selectedRange
              }}
            />

            <div className='custom-pagination'>
              <Button
                disabled={!users.pagination.previous}
                type='primary'
                onClick={() =>
                  dispatch(
                    fetchAllUsers(
                      selectedRange,
                      undefined,
                      users.pagination.previous as string
                    )
                  )
                }
              >
                Previous
              </Button>

              <Select
                defaultValue={"10 patients / page"}
                onChange={(val) => {
                  setSelectedRange(() => +val);
                  dispatch(fetchAllUsers(+val, undefined, undefined));
                }}
              >
                <Select.Option value={10}>10 patients / page</Select.Option>
                <Select.Option value={15}>15 patients / page</Select.Option>
                <Select.Option value={20}>20 patients / page</Select.Option>
              </Select>

              <Button
                disabled={!users.pagination.next}
                type='primary'
                onClick={() =>
                  dispatch(
                    fetchAllUsers(
                      selectedRange,
                      users.pagination.next as string,
                      undefined
                    )
                  )
                }
              >
                Next
              </Button>
            </div>
          </UsersTableBody>
          <AddUserModal
            isModalVisible={isAddUserModalVisible}
            handleCancel={() => setIsAddUserModalVisible(false)}
          />
        </UsersTableWrapper>
      </Card>
    </>
  );
};

export default UsersTable;
