import { TopupModalWrapper } from "./styles";
import { Col, InputNumber, Modal, Row, Form } from "antd";
import _ from "lodash";
import { PaystackButton } from "react-paystack";
import { useState } from "react";
import Button from "../../../../component/Button";
import { fetchAllTransactions, fetchBalance } from "../../../../redux/reducers/transactions";
import { useDispatch } from "react-redux";

const TopupModal = (props: any) => {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(0);

  const pneumaCurrentUser = JSON.parse(localStorage.getItem('pneumaCurrentUser') || "")

  const { email, first_name, last_name, integration, phone } = pneumaCurrentUser || {};

  const integrationMode = window.localStorage.getItem("integrationMode") || "test";

  const publicKey = integrationMode === "live"
    ? process.env.REACT_APP_PAYSTACK_PUBLIC_LIVE_KEY
    : process.env.REACT_APP_PAYSTACK_PUBLIC_TEST_KEY;

  const componentProps: any = {
    email,
    amount: amount * 100,
    reference: new Date().getTime(),
    metadata: {
      name: first_name + " " + last_name,
      phone,
      integration,
      mode: integrationMode === "live" ? integrationMode : "sandbox"
    },
    publicKey,
    text: "Top up wallet",
    onSuccess: () => {
      setAmount(0);
      setIsTopupModalVisible(false);
      dispatch(fetchAllTransactions());
      dispatch(fetchBalance());
    },
    onClose: () => console.log("closed"),
  }

  const { isTopupModalVisible, setIsTopupModalVisible } = props;

  const handleTopup = (values: any) => {
    // console.log(values)
  };

  return (
    <Modal
      title="Top up wallet"
      visible={isTopupModalVisible}
      cancelText="Cancel"
      footer={null}
      
      okText="Add Diagnosis"
      onCancel={() => setIsTopupModalVisible(false)}
      className="examination-modal main-modal"
    >
      <TopupModalWrapper>
        <Form onFinish={handleTopup} name="basicForm" layout="vertical">
          <Row gutter={24}>
            <Col span={24}>
              <label>Amount <small>(minumum of NGN 20,000)</small></label>
              <Form.Item
                name="amount"
                
                rules={[
                  {
                    required: true,
                    message: "Please input amount",
                  },
                  {
                    validator: (_, value) => {
                      if (!value && value !== 0) return Promise.resolve();
                      return parseFloat(value) >= 20000 ? Promise.resolve() : Promise.reject(new Error('Amount must not be less than NGN 20,000'))
                    }
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  width="100%"
                  onChange={(value: any) => setAmount(parseFloat(value))}
                  placeholder="Enter Amount"
                  style={{width: '100%', marginBottom: '10px'}}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} className="align-right">
              {
                amount >= 20000
                ? <PaystackButton type="submit" className="ant-btn btn-topUp paystack-button" {...componentProps} />
                : <Button type="primary" className="btn-topUp" htmlType="submit">Top up wallet</Button>
              }
            </Col>
          </Row>
        </Form>
      </TopupModalWrapper>
    </Modal>
  );
};

export default TopupModal;
