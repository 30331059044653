import { Table, Tooltip } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { capitalize } from "lodash";

import Button from "component/Button";
import { ReactComponent as RemoveProviderIcon } from "assets/icons/remove-provider-icon.svg";
import { TableWrapper, TableBody } from "../ProvidersTable/styles";

// svg
import { ReactComponent as EmptyProvidersIcon } from "assets/icons/empty-providers-icon.svg";
import { SingleLocalProvider } from "utils/apiTypes";
import RemoveProviderModal from "component/RemoveProviderModal";

type Props = {
  handleChangeTab: (key: string) => void;
  providers: SingleLocalProvider[];
  isLoading: boolean;
};

function MyProviders({ handleChangeTab, providers, isLoading }: Props) {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({
    name: "",
    id: ""
  });

  const columns = [
    {
      title: "Provider Name",
      dataIndex: ["service_provider", "name"],
      width: 386
    },
    {
      title: "Category",
      dataIndex: ["service_provider", "type"],
      render: (_: string, record: SingleLocalProvider) => {
        const dirtyType = record.service_provider.category;

        // remove underscores
        let cleanType = dirtyType.replace("_", " ");

        // seperate multiple words
        const cleanWord1 = cleanType.split(" ")[0];
        const cleanWord2 = cleanType.split(" ")[1];

        return `${capitalize(cleanWord1)} ${capitalize(cleanWord2)}`;
      }
    },
    {
      title: "State",
      dataIndex: ["service_provider", "address", "state"]
    },
    {
      title: "City/Town",
      dataIndex: ["service_provider", "address", "city"],
      render: (_: string, record: SingleLocalProvider) =>
        capitalize(record.service_provider.address.city)
    },
    {
      title: "",
      render: (_: string, record: SingleLocalProvider) => {
        return (
          <div className='action-btns'>
            <Tooltip title='Remove Provider'>
              <Button
                onClick={() => {
                  setSelectedProvider({
                    name: record.service_provider.name,
                    id: record.service_provider.id
                  });

                  setShowModal(true);
                }}
              >
                <RemoveProviderIcon />
              </Button>
            </Tooltip>
            <Button
              onClick={() =>
                history.push(`/providers/${record.service_provider.id}`)
              }
            >
              View Provider
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <TableWrapper>
        <TableBody>
          <Table
            dataSource={providers}
            columns={columns}
            loading={isLoading}
            scroll={!isLoading && providers?.length! > 0 ? { x: 700 } : {}}
            rowClassName={(_, index) =>
              index % 2 === 0 ? "even-row" : "odd-row"
            }
            pagination={{
              pageSize: 20
            }}
            locale={{
              emptyText: (
                <div className='empty-wrapper'>
                  <div className='empty-content'>
                    <EmptyProvidersIcon />
                    <h4>No Provider Added</h4>
                    <p>All providers in your network will show here</p>
                    <Button
                      onClick={() => handleChangeTab("all-providers")}
                      type='primary'
                    >
                      See all providers
                    </Button>
                  </div>
                </div>
              )
            }}
          />
        </TableBody>
      </TableWrapper>

      {selectedProvider.id ? (
        <RemoveProviderModal
          handleCancel={() => setShowModal(false)}
          isModalVisible={showModal}
          selectedProvider={selectedProvider}
        />
      ) : null}
    </>
  );
}

export default MyProviders;
